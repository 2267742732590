import { Component, OnInit } from '@angular/core';
declare const $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  fullYear: number = new Date().getFullYear();
  status = true;

  constructor() { }

  ngOnInit(): void {
    this.clickEvent();
    this.headerScroll();
    this.navLink();
  }

  clickEvent(): void {
    this.status = !this.status;
  }

  headerScroll() {
    $(window).scroll(function (): void {
      if ($(window).scrollTop() >= 100) {
        $('header').addClass('sticky');
      }
      else {
        $('header').removeClass('sticky');
      }
    });
  }

  navLink(): void {
    $('header .nav-blc a').click((): void => {
      $('header').removeClass('active');
      $('.mobile-nav').removeClass('active');
    });
  }



}
