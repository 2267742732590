<div class="home-area">

    <app-social-media></app-social-media>

    <section class="section-blc section-parallax section-1 img-section-1">
        <div class="main-content">
            <div class="caption sroll-animation">
                <p>
                    Qualité et sobriété architecturale
                    <br> Adaptation et convivialité des espaces
                </p>
            </div>
            <!--.caption-->
        </div>
        <!--.main-content-->
    </section>
    <!--.section-blc \ .section-1-->
    <section class="section-blc section-parallax section-2 img-section-2">
        <div class="main-content">
            <div class="caption sroll-animation">
                <p>
                    Maîtrise des coûts et des délais
                    <br>Fiabilité et durabilité technique…
                </p>
            </div>
            <!--.caption-->
        </div>
        <!--.main-content-->
    </section>
    <!--.section-blc \ .section-2-->
    <section class="section-blc section-parallax section-3 img-section-3">
        <div class="main-content">
            <div class="caption sroll-animation">
                <p>
                    Fonctionnalité et adéquation au programme...
                </p>
            </div>
            <!--.caption-->
        </div>
        <!--.main-content-->
    </section>
    <!--.section-blc \ .section-3-->
    <section class="section-blc about-section" id="apropos">
        <div class="main-content">
            <div class="section_title">
                <h1>A propos</h1>
            </div>
            <!--.section_title-->

            <div class="about-section_desc">
                <p>
                    L'agence MS ARCHITECTURE développe son activité principalement sur la région chartraine et le sud-est de la France.
                </p>
                <p>
                    Fort de plusieurs années d’expériences dans de grandes agences parisiennes, œuvrant dans des secteurs d’activités très variés,
                    Yann Sidoroff a fondé l’agence MS Architecture en 2005 à Chartres.
                </p>
                <p>
                    Allant de l’extension de maisons individuelles aux projets de promotion immobilière de plusieurs centaines de logements,
                    les domaines de compétences de l’agence sont des plus variés.
                </p>
                <p>
                    Les objectifs principaux qui régissent son travail sont hérités directement de cette expérience et apparaissent comme autant
                    de garants de réussite des opérations à réaliser :
                </p>
            </div>
            <!--.about-section_desc-->

            <div class="about-section_list">
                <ul>
                    <li class="about-section_list-item">
                        <p>Fonctionnalité et adéquation au programme</p>
                    </li>
                    <li class="about-section_list-item">
                        <p>Qualité et sobriété architecturale</p>
                    </li>

                    <li class="about-section_list-item">
                        <p>Adaptation et convivialité des espaces</p>
                    </li>
                    <li class="about-section_list-item">
                        <p>Fiabilité et durabilité technique</p>
                    </li>
                    <li class="about-section_list-item">
                        <p>Maîtrise des coûts et des délais</p>
                    </li>
                    <li class="about-section_list-item">
                        <p>Grande connaissance des normes environnementales (RT 2012, préparation à la RE 2020)</p>
                    </li>
                </ul>
            </div>
            <!--.about-section_list-->
            <div class="about-section_desc">
                <p>
                    Équipe :
                </p>
                <p>
                    Yann Sidoroff – Architecte DESA - Fondateur
                </p>
                <p>
                    Marion Lesage – Architecte DPLG – Cheffe de projets
                </p>
                <p>
                    Maxime Charbeau – Développement commercial
                </p>
            </div>
            <!--.about-section_desc-->
            <!--            <div class="meet-team-area grid-style-4">-->
            <!--                <div class="grid-blc">-->
            <!--                    <div class="meet-team-item">-->
            <!--                        <div class="meet-team-item_img">-->
            <!--                            <img src="assets/img/meet-team-item_img-2.png" alt="meet-team-item_img-2">-->
            <!--                        </div>-->
            <!--                        <div class="meet-team-item_desc">-->
            <!--                            <p>-->
            <!--                                Architecte en chef-->
            <!--                            </p>-->
            <!--                        </div>-->
            <!--                        &lt;!&ndash;.meet-team-item_desc&ndash;&gt;-->
            <!--                    </div>-->
            <!--                    &lt;!&ndash;.meet-team-item&ndash;&gt;-->

            <!--                </div>-->
            <!--                &lt;!&ndash;.grid-blc&ndash;&gt;-->
            <!--                <div class="grid-blc">-->
            <!--                    <div class="meet-team-item">-->
            <!--                        <div class="meet-team-item_img">-->
            <!--                            <img src="assets/img/meet-team-item_img-3.png" alt="meet-team-item_img-3">-->
            <!--                        </div>-->
            <!--                        <div class="meet-team-item_desc">-->
            <!--                            <p> Secrétaire spécialisée bâtiment</p>-->
            <!--                        </div>-->
            <!--                        &lt;!&ndash;.meet-team-item_desc&ndash;&gt;-->
            <!--                    </div>-->
            <!--                    &lt;!&ndash;.meet-team-item&ndash;&gt;-->

            <!--                </div>-->
            <!--                &lt;!&ndash;.grid-blc&ndash;&gt;-->
            <!--                <div class="grid-blc">-->
            <!--                    <div class="meet-team-item">-->
            <!--                        <div class="meet-team-item_img">-->
            <!--                            <img src="assets/img/meet-team-item_img-4.png" alt="meet-team-item_img-4">-->
            <!--                        </div>-->
            <!--                        <div class="meet-team-item_desc">-->
            <!--                            <p> Architecte projeteur / compositeur</p>-->
            <!--                        </div>-->
            <!--                        &lt;!&ndash;.meet-team-item_desc&ndash;&gt;-->
            <!--                    </div>-->
            <!--                    &lt;!&ndash;.meet-team-item&ndash;&gt;-->

            <!--                </div>-->
            <!--                &lt;!&ndash;.grid-blc&ndash;&gt;-->
            <!--                <div class="grid-blc">-->
            <!--                    <div class="meet-team-item">-->
            <!--                        <div class="meet-team-item_img">-->
            <!--                            <img src="assets/img/meet-team-item_img-5.png" alt="meet-team-item_img-5">-->
            <!--                        </div>-->
            <!--                        <div class="meet-team-item_desc">-->
            <!--                            <p> Ingénieur thermique.</p>-->
            <!--                        </div>-->
            <!--                        &lt;!&ndash;.meet-team-item_desc&ndash;&gt;-->
            <!--                    </div>-->
            <!--                    &lt;!&ndash;.meet-team-item&ndash;&gt;-->

            <!--                </div>-->
            <!--                &lt;!&ndash;.grid-blc&ndash;&gt;-->

            <!--            </div>-->
            <!--.meet-team-area-->
        </div>
        <!--.main-content-->
    </section>
    <!-- .section-blc \ .about-section -->

    <!--
    <section class="section-blc actualite-section">
        <div class="main-content">
            <div class="section_title">
                <h1 routerLink="/actualites">Actualités</h1>
            </div>
            <div class="actualite-slide">
                <ngx-slick-carousel class="carousel " #slickModal="slick-carousel" [config]="actualiteSlideConfig">
                    <div ngxSlickItem itemscope>
                        <div class="actualite-slide-grid">
                            <div class="actualite-slide-item">
                                <div class="actualite-slide-item_desc">
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    </p>
                                    <a class="see-more-link">Voir plus</a>
                                </div>
                                <div class="actualite-slide-item_img-blc">
                                    <div class="actualite-slide-item_img">
                                        <img src="assets/img/actualite-img-1.png" alt="actualite-img-1">
                                    </div>
                                    <time>juillet 2012</time>
                                </div>
                            </div>
                            <div class="actualite-slide-item">
                                <div class="actualite-slide-item_desc">
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    </p>
                                    <a class="see-more-link">Voir plus</a>
                                </div>
                                <div class="actualite-slide-item_img-blc">
                                    <div class="actualite-slide-item_img">
                                        <img src="assets/img/actualite-img-2.png" alt="actualite-img-2">
                                    </div>
                                    <time>juillet 2012</time>
                                </div>
                            </div>

                            <div class="actualite-slide-item">
                                <div class="actualite-slide-item_desc">
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    </p>
                                    <a class="see-more-link">Voir plus</a>
                                </div>
                                <div class="actualite-slide-item_img-blc">
                                    <div class="actualite-slide-item_img">
                                        <img src="assets/img/actualite-img-3.png" alt="actualite-img-3">
                                    </div>
                                    <time>juillet 2012</time>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div ngxSlickItem itemscope>
                        <div class="actualite-slide-grid">
                            <div class="actualite-slide-item">
                                <div class="actualite-slide-item_desc">
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    </p>
                                    <a class="see-more-link">Voir plus</a>
                                </div>
                                <div class="actualite-slide-item_img-blc">
                                    <div class="actualite-slide-item_img">
                                        <img src="assets/img/actualite-img-1.png" alt="actualite-img-1">
                                    </div>
                                    <time>juillet 2012</time>
                                </div>
                            </div>

                            <div class="actualite-slide-item">
                                <div class="actualite-slide-item_desc">
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    </p>
                                    <a class="see-more-link">Voir plus</a>
                                </div>
                                <div class="actualite-slide-item_img-blc">
                                    <div class="actualite-slide-item_img">
                                        <img src="assets/img/actualite-img-2.png" alt="actualite-img-2">
                                    </div>
                                    <time>juillet 2012</time>
                                </div>
                            </div>

                            <div class="actualite-slide-item">
                                <div class="actualite-slide-item_desc">
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    </p>
                                    <a class="see-more-link">Voir plus</a>
                                </div>
                                <div class="actualite-slide-item_img-blc">
                                    <div class="actualite-slide-item_img">
                                        <img src="assets/img/actualite-img-3.png" alt="actualite-img-3">
                                    </div>
                                    <time>juillet 2012</time>
                                </div>
                            </div>
                        </div>
                    </div>

                </ngx-slick-carousel>
            </div>
        </div>
    </section>-->
    <!-- .section-blc \ .actualite-section -->

    <section class="section-blc projet-section">
        <div class="main-content">
            <div class="section_title">
                <h1 routerLink="/nos-projets">NOS PROJETS ET CRÉATIONS</h1>
            </div>
            <!--.section_title-->

            <div class="projets-slide-group">
                <div class="grid-style-3 gap">
                    <div class="grid-blc">
                        <div class="projets-slide-blc harvest-color" routerLink="/logements">
                            <div class="projets-slide_desc">
                                <h3>logements</h3>
                            </div>
                            <!--.projets-slide_desc-->

                            <div class="projets-slide_img">
                                <img src="assets/img/logements/logements.png" alt="logements">
                            </div>
                        </div>
                        <!--.projets-slide-blc-->
                    </div>
                    <div class="grid-blc">
                        <div class="projets-slide-blc viking-color" routerLink="/activites-bureaux">
                            <div class="projets-slide_desc">
                                <h3>Activités / Commerces / Bureaux</h3>
                            </div>
                            <!--.projets-slide_desc-->
                            <div class="projets-slide_img">
                                <img src="assets/img/activites-bureaux/article-11/article-11-6.jpg" alt="bureaux">
                            </div>
                        </div>
                        <!--.projets-slide-blc-->
                    </div>

                    <div class="grid-blc">
                        <div class="projets-slide-blc olive-drab-color" routerLink="/equip-publiques">
                            <div class="projets-slide_desc">
                                <h3>Équipements publics/Scolaire</h3>
                            </div>
                            <!--.projets-slide_desc-->
                            <div class="projets-slide_img">
                                <img src="assets/img/equip-publiques/equip-publiques.png" alt="equip-publiques">
                            </div>
                        </div>
                        <!--.projets-slide-blc-->
                    </div>

                </div>
                <!--.grid-style-2-->
            </div>
            <!--.projets-slide-group-->



        </div>
    </section>
    <!-- .section-blc \ .projet-section -->

    <section class="section-blc contact-section">
        <div class="main-content">
            <div class="section_title">
                <h1 routerLink="/contactez-nous">Contactez-nous</h1>
            </div>
            <!--.section_title-->
            <form (ngSubmit)="onSubmit()" [formGroup]="contactForm">
                <div class="form-area">
                    <div class="grid-style-2">
                        <div class="grid-blc">
                            <div class="form-group">
                                <label for="">Votre nom :</label>
                                <input formControlName="firstname" type="text" class="form-control">
                            </div>
                            <!--.form-group-->
                            <div class="form-group">
                                <label for="">Votre prénom :</label>
                                <input formControlName="lastname" type="text" class="form-control">
                            </div>
                            <!--.form-group-->
                            <div class="form-group">
                                <label for="">Votre email :</label>
                                <input formControlName="from" type="text" class="form-control">
                                <div class="error" *ngIf="submitted && contactForm.get('from').invalid">
                                    L'email est requis, et doit être valide.
                                </div>
                            </div>
                            <!--.form-group-->
                        </div>
                        <div class="grid-blc">
                            <div class="form-group">
                                <label for="">Votre message :</label>
                                <textarea formControlName="body" class="form-control" name="" id=""></textarea>
                            </div>
                            <!--.form-group-->
                            <div class="btns text-right">
                                <button type="submit" [disabled]="isLoading" class="btn btn-gold">Envoyer</button>
                                <div class="success" *ngIf="serverResponse.success">
                                    Message envoyé !
                                </div>
                                <div class="error" *ngIf="serverResponse.hasError()">
                                  Message n'est pas envoyé, réessayer plus tard..
                                </div>
                            </div>
                            <!--.btns-->
                        </div>
                    </div>
                    <!--.grid-style-2-->
                </div>
                <!--.form-area-->
            </form>
        </div>
        <!--.main-content-->
    </section>
    <!-- .section-blc \ .about-section -->
</div>
<!--.home-area-->
