<div class="section-header section-parallax section-1">
    <app-social-media></app-social-media>
    <div class="main-content">
        <div class="caption">
            <p>
                Qualité et sobriété architecturale
                <br> Adaptation et convivialité des espaces
            </p>
        </div>
        <!--.caption-->
    </div>
    <!--.main-content-->
</div>
<!--.section-header-->


<section class="logements-area">
    <div class="main-content">
        <div class="section_title black-style">
            <h1>nos projets et créations - Restauration</h1>
        </div>

        <div class="accordion-area">
            <accordion [closeOthers]="oneAtATime" [isAnimated]="true">
                <accordion-group heading="Vert D'O" [isOpen]="isFirstOpen">

                    <div class="logement-item">
                        <div class="logement-item-desc">
                            <p>
                                L’Agence MS Architecture a été chargée de réaliser la conception et les travaux d’un petit restaurant rapide dans le centre
                                de la ville de Chartres.
                            </p>
                            <p>
                                Ce restaurant à la particularité de proposer de plats frais avec des produits de grande qualité.
                            </p>
                        </div>
                        <!--.logement-item-desc-->

                        <div class="logement-lightbox">
                            <div class="logement-lightbox_img">
                                <a href="assets/img/restauration/article-1/vertdo.jpg" data-fancybox="images">
                                    <img src="assets/img/restauration/article-1/vertdo_m.jpg" alt="vertdo_m">
                                </a>
                            </div>
                            <div class="logement-lightbox_img">
                                <a href="assets/img/restauration/article-1/vertdo2.jpg" data-fancybox="images">
                                    <img src="assets/img/restauration/article-1/vertdo2_m.jpg" alt="vertdo2_m">
                                </a>
                            </div>
                        </div>
                        <!--.logement-lightbox-->
                    </div>
                    <!--.logement-item-->

                </accordion-group>
                <accordion-group heading="11 Cours Gabriel">

                    <div class="logement-item">
                        <div class="logement-item-desc">
                            <p>
                                M. Jallerat, propriétaire de l'hôtel « Le Grand Monarque », dont le restaurant « Le Georges » possède une étoile au guide
                                Michelin à Chartres a confié à l'agence la réhabilitation d'un ancien garage en salle de
                                cours de cuisine et de réception.
                            </p>
                            <p>
                                L'esprit industriel du lieu a été conservé par la restauration complète de la charpente Eiffel (une véritable prouesse technique)
                                et de la façade en pierres silex et la création d'une verrière en toiture. A peine réceptionnés,
                                les invités ont été enchantés par l'âme du lieu.
                            </p>
                        </div>
                        <!--.logement-item-desc-->

                        <div class="logement-lightbox">
                            <div class="logement-lightbox_img">
                                <a href="assets/img/restauration/article-2/coursgabriel_facade_interieur.jpg" data-fancybox="images-2">
                                    <img src="assets/img/restauration/article-2/coursgabriel_facade_interieur_m.jpg" alt="coursgabriel_facade_interieur_m">
                                </a>
                            </div>
                            <div class="logement-lightbox_img">
                                <a href="assets/img/restauration/article-2/coursgabriel_ilot_central.jpg" data-fancybox="images-2">
                                    <img src="assets/img/restauration/article-2/coursgabriel_ilot_central_m.jpg" alt="coursgabriel_ilot_central_m">
                                </a>
                            </div>
                            <div class="logement-lightbox_img">
                                <a href="assets/img/restauration/article-2/coursgabriel_sas_entree.jpg" data-fancybox="images-2">
                                    <img src="assets/img/restauration/article-2/coursgabriel_sas_entree_m.jpg" alt="coursgabriel_sas_entree_m">
                                </a>
                            </div>
                            <div class="logement-lightbox_img">
                                <a href="assets/img/restauration/article-2/coursgabriel_vue_cuisine.jpg" data-fancybox="images-2">
                                    <img src="assets/img/restauration/article-2/coursgabriel_vue_cuisine_m.jpg" alt="coursgabriel_vue_cuisine_m">
                                </a>
                            </div>
                            <div class="logement-lightbox_img">
                                <a href="assets/img/restauration/article-2/projet11coursfacade.jpg" data-fancybox="images-2">
                                    <img src="assets/img/restauration/article-2/projet11coursfacade_m.jpg" alt="projet11coursfacade_m">
                                </a>
                            </div>
                        </div>
                        <!--.logement-lightbox-->
                    </div>
                    <!--.logement-item-->

                </accordion-group>

            </accordion>
        </div>
        <!--.accordion-area-->

    </div>
    <!--.main-content-->
</section>
<!--.logements-area-->