<div class="section-header section-parallax section-1 img-section-11">
    <app-social-media></app-social-media>
    <div class="main-content">
        <div class="caption">
            <p>
                Qualité et sobriété architecturale
                <br> Adaptation et convivialité des espaces
            </p>
        </div>
        <!--.caption-->
    </div>
    <!--.main-content-->
</div>
<!--.section-header-->

<section class="actualites-area">
    <div class="main-content">
        <div class="section_title black-style">
            <h1>Actualités</h1>
        </div>

        <div class="actualites-description">
            <p>
                C’est fait ! Nouvelle agence, nouveau challenge !
            </p>
            <p>
                L’Agence François Thoniel, créée il y a 35 ans est aujourd’hui l’Agence Sidoroff-Thoniel Architectes.
                Au nord ou au sud, la volonté reste la même : répondre aux souhaits de nos clients.
            </p>
            <p>
                Nous ne travaillons pas dans l’idée d’une architecture uniquement technique, mais en nous attachant à mettre l’humain au cœur de nos projets.
            </p>
            <p>
                Et toujours : créer, innover, surprendre.
            </p>
            <p>
                Agence Sidoroff-Thoniel : site en construction, à découvrir très prochainement !
            </p>
        </div>

        <!--        <div class="accordion-area">-->
        <!--            <accordion [closeOthers]="oneAtATime" [isAnimated]="true">-->
        <!--                <accordion-group heading="2012" [isOpen]="isFirstOpen">-->
        <!--                    <div class="news-area">-->
        <!--                        <div class="news-item">-->
        <!--                            <div class="news-item_img">-->
        <!--                                <img src="assets/img/article33.jpg" alt="article33">-->
        <!--                            </div>-->
        <!--                            &lt;!&ndash;.news-item_img&ndash;&gt;-->
        <!--                            <div class="news-item_description">-->
        <!--                                <time>Janvier 2012</time>-->
        <!--                                <p>-->
        <!--                                    L'agence MS Architecture a souhaité s'agrandir. Dans ce but, elle a racheté l'atelier d'architecture « Guy Ducellier » historiquement-->
        <!--                                    connu sur la commune d'Evreux depuis 1939. Grâce à cette croissance externe, des projets-->
        <!--                                    d'envergures (mairies, promoteurs privés de logements, bailleurs sociaux.) sont en cours-->
        <!--                                    de réalisation.-->


        <!--                                </p>-->
        <!--                            </div>-->
        <!--                        </div>-->
        <!--                        &lt;!&ndash;.news-item&ndash;&gt;-->
        <!--                        <div class="news-item">-->
        <!--                            <div class="news-item_img">-->
        <!--                                <img src="assets/img/article2.jpg" alt="article2">-->
        <!--                            </div>-->
        <!--                            &lt;!&ndash;.news-item_img&ndash;&gt;-->
        <!--                            <div class="news-item_description">-->
        <!--                                <time>Septembre 2012</time>-->
        <!--                                &lt;!&ndash;<p>-->
        <!--                                            L'agence MS Architecture a souhaité s'agrandir. Dans ce but, elle a racheté l'atelier d'architecture « Guy Ducellier » historiquement-->
        <!--                                            connu sur la commune d'Evreux depuis 1939. Grâce à cette croissance externe, des projets-->
        <!--                                            d'envergures (mairies, promoteurs privés de logements, bailleurs sociaux.) sont en cours-->
        <!--                                            de réalisation.-->
        <!--                                        </p>&ndash;&gt;-->
        <!--                            </div>-->
        <!--                        </div>-->
        <!--                        &lt;!&ndash;.news-item&ndash;&gt;-->
        <!--                        <div class="news-item">-->
        <!--                            <div class="news-item_img">-->
        <!--                                <img src="assets/img/article1.jpg" alt="article1">-->
        <!--                            </div>-->
        <!--                            &lt;!&ndash;.news-item_img&ndash;&gt;-->
        <!--                            <div class="news-item_description">-->
        <!--                                <time>Octobre 2012</time>-->
        <!--                                &lt;!&ndash;<p>-->
        <!--                                            L'agence MS Architecture a souhaité s'agrandir. Dans ce but, elle a racheté l'atelier d'architecture « Guy Ducellier » historiquement-->
        <!--                                            connu sur la commune d'Evreux depuis 1939. Grâce à cette croissance externe, des projets-->
        <!--                                            d'envergures (mairies, promoteurs privés de logements, bailleurs sociaux.) sont en cours-->
        <!--                                            de réalisation.-->
        <!--                                        </p>&ndash;&gt;-->
        <!--                            </div>-->
        <!--                        </div>-->
        <!--                        &lt;!&ndash;.news-item&ndash;&gt;-->
        <!--                    </div>-->
        <!--                    &lt;!&ndash;.news-area&ndash;&gt;-->
        <!--                </accordion-group>-->
        <!--                <accordion-group heading="2011">-->

        <!--                    <div class="news-area">-->
        <!--                        <div class="news-item">-->
        <!--                            <div class="news-item_img">-->
        <!--                                <img src="assets/img/news-img-1.png" alt="news-img-1">-->
        <!--                            </div>-->
        <!--                            &lt;!&ndash;.news-item_img&ndash;&gt;-->
        <!--                            <div class="news-item_description">-->
        <!--                                <time>Juin 2011</time>-->
        <!--                                &lt;!&ndash; <p>-->
        <!--                                    L'agence MS Architecture a souhaité s'agrandir. Dans ce but, elle a racheté l'atelier d'architecture « Guy Ducellier » historiquement-->
        <!--                                    connu sur la commune d'Evreux depuis 1939. Grâce à cette croissance externe, des projets-->
        <!--                                    d'envergures (mairies, promoteurs privés de logements, bailleurs sociaux.) sont en cours-->
        <!--                                    de réalisation.-->
        <!--                                </p>&ndash;&gt;-->
        <!--                            </div>-->
        <!--                        </div>-->
        <!--                        &lt;!&ndash;.news-item&ndash;&gt;-->
        <!--                    </div>-->
        <!--                    &lt;!&ndash;.news-area&ndash;&gt;-->

        <!--                </accordion-group>-->

        <!--            </accordion>-->
        <!--        </div>-->
        <!--.accordion-area-->


    </div>
    <!--.main-content-->
</section>
<!--.actualites-area-->