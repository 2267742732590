import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ContacterNousService } from '../_services/contacter-nous.service';

@Component({
  selector: 'app-contactez-nous',
  templateUrl: './contactez-nous.component.html',
  styleUrls: ['./contactez-nous.component.scss']
})
export class ContactezNousComponent implements OnInit, OnDestroy {
  contactForm: any;
  isLoading = false;
  serverResponse: any = {
    hasError: function() {
      return this.errors.length > 0;
    },
    reset: function() {
      this.success = null;
      this.initialized = true;
      this.errors = [];
    },
    errors: [],
    success: null,
    initialized: true
  };
  submitted = false;
  subscription: any;

  constructor(
    private fb: FormBuilder,
    private contactService: ContacterNousService
  ) { }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    const emailPattern = /^\w+[0-9\.\-\w]+@[0-9\-\w]+\.\w{2,3}$/
    this.contactForm = this.fb.group({
        from : ['', [Validators.required,Validators.pattern(emailPattern)]],
        body : [''],
        firstname: [''],
        lastname: ['']
    });
    this.subscription = this.contactForm.valueChanges
    .subscribe(
      (data: any)=> {
        if(!this.serverResponse.initialized){
          this.serverResponse.reset();
        }
    }
    );
  }

  onSubmit(){
    this.submitted = true;
    if(this.contactForm.invalid){
      return;
    }
    const body = this.contactForm.value;
    this.isLoading = true;
      this.contactService
      .sendMail(body)
      .subscribe(
        response => {
          if(response.body.success) {
            this.serverResponse.success = true;
            this.serverResponse.initialized = false;
          }
          if(response.body.error) {
            this.serverResponse.initialized = false;
            this.serverResponse.errors.push(response.body.error);
          }else{
            this.serverResponse.reset();
          }
          if(response.body.success) {
            this.serverResponse.success = true;
            this.serverResponse.initialized = false;
          }
          this.isLoading = false;
          this.submitted = false;
        },
        error => {
          this.isLoading = false;
          //this.serverResponse.initialized = false;
          this.serverResponse.reset();
          this.serverResponse.errors.push(error.error);
        }
      )
  }

}
