<div class="section-header section-parallax section-1 img-section-13">
    <app-social-media></app-social-media>
    <div class="main-content">
        <div class="caption">
            <p>
                Qualité et sobriété architecturale
                <br> Adaptation et convivialité des espaces
            </p>
        </div>
        <!--.caption-->
    </div>
    <!--.main-content-->
</div>
<!--.section-header-->

<section class="contactez-nous-area">
    <div class="main-content">
        <div class="section_title black-style">
            <h1>contactez-nous</h1>
        </div>
        <div class="contactez-nous_content grid-style-2 gap">
            <div class="grid-blc">
                <div class="map-area">
                    <!--<img src="assets/img/map-img.jpg" alt="map-img">-->
                    <iframe frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?q=56%20avenue%20Jean%20Mermoz%20%E2%80%93%2028000%20Chartres&t=&z=13&ie=UTF8&iwloc=&output=embed"></iframe>
                </div>
                <!--.map-area-->
                <div class="contact-list">
                    <ul>
                        <li class="contact-list-item location-icn">
                            <span>56 avenue Jean Mermoz – 28000 Chartres</span>
                        </li>
                        <li class="contact-list-item phone-icn-2">
                            <span>02 37 21 49 26</span>
                        </li>
                    </ul>
                </div>
                <!--.contact-list-->
            </div>
            <!--.grid-blc-->
            <div class="grid-blc">
                <form (ngSubmit)="onSubmit()" [formGroup]="contactForm">
                    <div class="form-area">
                        <div class="form-group">
                            <label for="">Votre nom :</label>
                            <input formControlName="firstname" type="text" class="form-control">
                        </div>
                        <!--.form-group-->
                        <div class="form-group">
                            <label for="">Votre prénom :</label>
                            <input formControlName="lastname" type="text" class="form-control">
                        </div>
                        <!--.form-group-->
                        <div class="form-group">
                            <label for="">Votre email :</label>
                            <input formControlName="from" type="text" class="form-control">
                            <div class="error" *ngIf="submitted && contactForm.get('from').invalid">
                                L'email est requis, et doit être valide.
                            </div>
                        </div>
                        <!--.form-group-->

                        <div class="form-group">
                            <label for="">Votre message :</label>
                            <textarea formControlName="body" class="form-control" name="" id=""></textarea>
                        </div>
                        <!--.form-group-->
                        <div class="btns text-right">
                            <button type="submit" [disabled]="isLoading" class="btn btn-gold">Envoyer</button>
                            <div class="success" *ngIf="serverResponse.success">
                                Message envoyé !
                            </div>
                            <div class="error" *ngIf="serverResponse.hasError()">
                                Message n'est pas envoyé, réessayer plus tard..
                            </div>
                        </div>
                        <!--.btns-->
                    </div>
                    <!--.form-area-->
                </form>
            </div>
            <!--.grid-blc-->
        </div>
        <!--.contactez-nous_content-->

    </div>
    <!--.main-content-->
</section>
<!--.contactez-nous-area-->
