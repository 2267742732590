import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SlickCarouselModule } from 'ngx-slick-carousel';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { ActualitesComponent } from './actualites/actualites.component';
import { SocialMediaComponent } from './shared/social-media/social-media.component';
import { ContactezNousComponent } from './contactez-nous/contactez-nous.component';
import { MesProjetsComponent } from './mes-projets/mes-projets.component';
import { LogementsComponent } from './logements/logements.component';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { TertiaireComponent } from './tertiaire/tertiaire.component';
import { CommercesComponent } from './commerces/commerces.component';
import { EquipPubliquesComponent } from './equip-publiques/equip-publiques.component';
import { IndustrielComponent } from './industriel/industriel.component';
import { HotellerieComponent } from './hotellerie/hotellerie.component';
import { RestaurationComponent } from './restauration/restauration.component';
import { ActivitesBureauxComponent } from './activites-bureaux/activites-bureaux.component';
import { environment } from '../environments/environment';

/**
 * Ngx cookie consent configuration
 */
const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    // tslint:disable-next-line:max-line-length
    domain: environment.cookieDomain // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: 'rgba(0,0,0,1)'
    },
    button: {
      background: '#E5BE31'
    }
  },
  theme: 'block',
  type: 'info',
  content: {
    href: environment.cookieUrl
  }
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    ActualitesComponent,
    SocialMediaComponent,
    ContactezNousComponent,
    MesProjetsComponent,
    LogementsComponent,
    TertiaireComponent,
    CommercesComponent,
    EquipPubliquesComponent,
    IndustrielComponent,
    HotellerieComponent,
    RestaurationComponent,
    ActivitesBureauxComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    SlickCarouselModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AccordionModule.forRoot(),
    NgcCookieConsentModule.forRoot(cookieConfig)
  ],
  providers: [],
  bootstrap: [AppComponent]
})


export class AppModule { }
