<div class="section-header section-parallax section-1">
    <app-social-media></app-social-media>
    <div class="main-content">
        <div class="caption">
            <p>
                Qualité et sobriété architecturale
                <br> Adaptation et convivialité des espaces
            </p>
        </div>
        <!--.caption-->
    </div>
    <!--.main-content-->
</div>
<!--.section-header-->


<section class="logements-area">
    <div class="main-content">
        <div class="section_title black-style">
            <h1>nos projets et créations - Tertiaire</h1>
        </div>

        <div class="accordion-area">
            <accordion [closeOthers]="oneAtATime" [isAnimated]="true">
                <accordion-group heading="Groupama" [isOpen]="isFirstOpen">

                    <div class="logement-item">
                        <div class="logement-item-desc">
                            <p>
                                Groupama a confié à l’Agence MS Architecture la réhabilitation d’une grande partie de ses points de vente.
                            </p>
                            <p>
                                Cette mission s’étend de la conception à la livraison des agences, dans 5 départements que sont l’Orne, la Sarthe, l’Eure
                                et Loir, l’Eure et la Mayenne.
                            </p>
                        </div>
                        <!--.logement-item-desc-->

                        <div class="logement-lightbox">
                            <div class="logement-lightbox_img">
                                <a href="assets/img/tertiaire/article-1/auneaublanc.jpg" data-fancybox="images">
                                    <img src="assets/img/tertiaire/article-1/auneaublanc_m.jpg" alt="auneaublanc_m">
                                </a>
                            </div>
                            <div class="logement-lightbox_img">
                                <a href="assets/img/tertiaire/article-1/chartres.jpg" data-fancybox="images">
                                    <img src="assets/img/tertiaire/article-1/chartres_m.jpg" alt="chartres_m">
                                </a>
                            </div>
                            <div class="logement-lightbox_img">
                                <a href="assets/img/tertiaire/article-1/chartres2.jpg" data-fancybox="images">
                                    <img src="assets/img/tertiaire/article-1/chartres2_m.jpg" alt="chartres2_m">
                                </a>
                            </div>
                            <div class="logement-lightbox_img">
                                <a href="assets/img/tertiaire/article-1/b3.jpg" data-fancybox="images">
                                    <img src="assets/img/tertiaire/article-1/b3_m.jpg" alt="b3_m">
                                </a>
                            </div>
                            <div class="logement-lightbox_img">
                                <a href="assets/img/tertiaire/article-1/b.jpg" data-fancybox="images">
                                    <img src="assets/img/tertiaire/article-1/b_m.jpg" alt="b_m">
                                </a>
                            </div>
                            <div class="logement-lightbox_img">
                                <a href="assets/img/tertiaire/article-1/b2.jpg" data-fancybox="images">
                                    <img src="assets/img/tertiaire/article-1/b2_m.jpg" alt="b2_m">
                                </a>
                            </div>
                            <div class="logement-lightbox_img">
                                <a href="assets/img/tertiaire/article-1/bihorel.jpg" data-fancybox="images">
                                    <img src="assets/img/tertiaire/article-1/bihorel_m.jpg" alt="bihorel_m">
                                </a>
                            </div>
                            <div class="logement-lightbox_img">
                                <a href="assets/img/tertiaire/article-1/brionne.jpg" data-fancybox="images">
                                    <img src="assets/img/tertiaire/article-1/brionne_m.jpg" alt="brionne_m">
                                </a>
                            </div>
                            <div class="logement-lightbox_img">
                                <a href="assets/img/tertiaire/article-1/gaillon.jpg" data-fancybox="images">
                                    <img src="assets/img/tertiaire/article-1/gaillon_m.jpg" alt="gaillon_m">
                                </a>
                            </div>

                            <div class="logement-lightbox_img">
                                <a href="assets/img/tertiaire/article-1/gaillon.jpg" data-fancybox="images">
                                    <img src="assets/img/tertiaire/article-1/gaillon_m.jpg" alt="gaillon_m">
                                </a>
                            </div>

                        </div>
                        <!--.logement-lightbox-->
                    </div>
                    <!--.logement-item-->

                </accordion-group>

                <accordion-group heading="Bâtiment de bureaux à Chartres (28)">

                    <div class="logement-item">
                        <div class="logement-item-desc">
                            <p>
                                La société WPH Holding souhaite déménager son siège social de Chartres dans un bâtiment neuf, résolument moderne et tourné
                                vers le respect de l’environnement.
                            </p>
                            <p>
                                Notre Agence est en charge du projet dans son intégralité, de la conception à la livraison.
                            </p>
                        </div>
                        <!--.logement-item-desc-->

                        <div class="logement-lightbox">
                            <div class="logement-lightbox_img">
                                <a href="assets/img/tertiaire/article-2/IMG_2318-1024.jpg" data-fancybox="images-2">
                                    <img src="assets/img/tertiaire/article-2/IMG_2318_m.jpg" alt="IMG_2318_m">
                                </a>
                            </div>
                            <div class="logement-lightbox_img">
                                <a href="assets/img/tertiaire/article-2/IMG_2319-1024.jpg" data-fancybox="images-2">
                                    <img src="assets/img/tertiaire/article-2/IMG_2319-200.jpg" alt="IMG_2319-200">
                                </a>
                            </div>
                            <div class="logement-lightbox_img">
                                <a href="assets/img/tertiaire/article-2/IMG_2320-1024.jpg" data-fancybox="images-2">
                                    <img src="assets/img/tertiaire/article-2/IMG_2320-200.jpg" alt="IMG_2320-200">
                                </a>
                            </div>
                            <div class="logement-lightbox_img">
                                <a href="assets/img/tertiaire/article-2/IMG_2321-1024.jpg" data-fancybox="images-2">
                                    <img src="assets/img/tertiaire/article-2/IMG_2321-200.jpg" alt="IMG_2321-200">
                                </a>
                            </div>

                        </div>
                        <!--.logement-lightbox-->
                    </div>
                    <!--.logement-item-->

                </accordion-group>

                <accordion-group heading="Bâtiment de bureaux à Anet (28)">

                    <div class="logement-item">
                        <div class="logement-item-desc">
                            <p>
                                La SCI I CONCEPT, promoteur d'Anet (28) a confié à l'agence la construction et l'aménagement d'un bâtiment de bureaux comprenant
                                :
                            </p>
                            <p>
                                Le siège de la communauté de communes Val d'Eure et Vesgre d'une surface de 700 m2, comprenant 10 bureaux, des salles d'archive,
                                deux salles de réunion.
                            </p>
                            <p>
                                L'agence de la Caisse d'Epargne d'Anet avec DAB.
                            </p>
                            <p>
                                L'agent d'assurance Générali d'Anet d'une surface de 240 m2, comprenant 8 à 10 postes de travail, une salle de réunion, des
                                archives et une tisanerie.
                            </p>
                        </div>
                        <!--.logement-item-desc-->

                        <div class="logement-lightbox">
                            <div class="logement-lightbox_img">
                                <a href="assets/img/tertiaire/article-3/anet_caisse_epargne.jpg" data-fancybox="images-3">
                                    <img src="assets/img/tertiaire/article-3/anet_caisse_epargne_m.jpg" alt="anet_caisse_epargne_m">
                                </a>
                            </div>
                            <div class="logement-lightbox_img">
                                <a href="assets/img/tertiaire/article-3/anet_exterieur.jpg" data-fancybox="images-3">
                                    <img src="assets/img/tertiaire/article-3/anet_exterieur_m.jpg" alt="anet_exterieur_m">
                                </a>
                            </div>
                            <div class="logement-lightbox_img">
                                <a href="assets/img/tertiaire/article-3/anet_bureau.jpg" data-fancybox="images-3">
                                    <img src="assets/img/tertiaire/article-3/anet_bureau_m.jpg" alt="anet_bureau_m">
                                </a>
                            </div>
                            <div class="logement-lightbox_img">
                                <a href="assets/img/tertiaire/article-3/anet_couloir.jpg" data-fancybox="images-3">
                                    <img src="assets/img/tertiaire/article-3/anet_couloir_m.jpg" alt="anet_couloir_m">
                                </a>
                            </div>
                        </div>
                        <!--.logement-lightbox-->
                    </div>
                    <!--.logement-item-->

                </accordion-group>


                <accordion-group heading="Réhabilitation de l'agence Crédit Agricole de Voves (28)">

                    <div class="logement-item">
                        <div class="logement-item-desc">
                            <p>
                                Le Crédit Agricole Val de France a confié à l'Agence la réhabilitation de son point de vente de Voves.

                            </p>
                            <p>
                                Au premier étage, un open space et des salons de réception ont été créés.

                            </p>
                            <p>
                                Au sous sol, une salle du conseil de 25 places permettant aux caisses locales de se réunir dans des conditions de grand confort
                                a été réalisée.
                            </p>
                            <p>
                                Un soin particulier fut apporté à la lumière artificielle par la mise en place d'éclairage indirect à LED (cordons).
                            </p>
                            <p>
                                La particularité de cette opération réside en l’isolation complète extérieure du bâtiment.
                            </p>
                        </div>
                        <!--.logement-item-desc-->

                        <div class="logement-lightbox">
                            <div class="logement-lightbox_img">
                                <a href="assets/img/tertiaire/article-4/voves.jpg" data-fancybox="images-4">
                                    <img src="assets/img/tertiaire/article-4/voves_m.jpg" alt="voves_m">
                                </a>
                            </div>
                            <div class="logement-lightbox_img">
                                <a href="assets/img/tertiaire/article-4/voves_ca.jpg" data-fancybox="images-4">
                                    <img src="assets/img/tertiaire/article-4/voves_ca_m.jpg" alt="voves_ca_m">
                                </a>
                            </div>
                            <div class="logement-lightbox_img">
                                <a href="assets/img/tertiaire/article-4/voves_salle_conseil1.jpg" data-fancybox="images-4">
                                    <img src="assets/img/tertiaire/article-4/voves_salle_conseil1_m.jpg" alt="voves_salle_conseil1_m">
                                </a>
                            </div>
                            <div class="logement-lightbox_img">
                                <a href="assets/img/tertiaire/article-4/voves_salle_conseil2.jpg" data-fancybox="images-4">
                                    <img src="assets/img/tertiaire/article-4/voves_salle_conseil2_m.jpg" alt="voves_salle_conseil2_m">
                                </a>
                            </div>
                        </div>
                        <!--.logement-lightbox-->
                    </div>
                    <!--.logement-item-->

                </accordion-group>



            </accordion>
        </div>
        <!--.accordion-area-->

    </div>
    <!--.main-content-->
</section>
<!--.logements-area-->