<header [ngClass]="status ? 'active' : ''">
    <div class="header-area">
        <div class="main-content">
            <div class="mobile-nav" (click)="clickEvent()" [ngClass]="status ? 'active' : ''">
                <span></span>
            </div>
           <!--  <div class="logo-company" routerLink="/">
                <img src="assets/img/logo.png" alt="logo">
            </div> -->
            <div class="logo-company flex-logos">
                <div class="center-logos " routerLink="/">
                    <img src="assets/img/nouv-logo/logo.png" alt="logo">
                </div>
                <div class="center-logos " routerLink="/">
                    <img src="assets/img/nouv-logo/logo2.png" alt="logo">
                </div>
            </div>
            <nav>
                <div class="nav-blc">
                    <a routerLink="/">Accueil</a>
                    <a routerLink="/actualites">Actualites</a>
                    <a href="#apropos">A propos</a>
                </div>
                <!--.nav-blc-->
                <div class="nav-blc">
                    <a routerLink="/nos-projets">Nos projets</a>
                    <a routerLink="/contactez-nous">Contactez-nous</a>
                </div>
                <!--.nav-blc-->
            </nav>

            <div class="copyright-header">
                © {{fullYear}} Copyright MS-Architecture. Tous droits réservés.
            </div>

        </div>
        <!--.main-content-->
    </div>
    <!--.header-area-->
</header>