import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-activites-bureaux',
  templateUrl: './activites-bureaux.component.html',
  styleUrls: ['./activites-bureaux.component.scss']
})
export class ActivitesBureauxComponent implements OnInit {
  oneAtATime: boolean = true;
  isFirstOpen: boolean = true;
  constructor() { }

  ngOnInit(): void {
    this.projectItemEvent();
  }

  projectItemEvent(): void {
    $('.project-item_desc').bind('click', (event: any) => {
      $('.project-item').removeClass('active');
      setTimeout(() => {
        $(event.currentTarget).parent('.project-item').addClass('active');
      }, 400);
    });
  }

  fancyboxFct(): void {
    $('[data-fancybox="images-1"]').fancybox({
      afterLoad: function (instance: any, current: any) {
        const pixelRatio = window.devicePixelRatio || 1;

        if (pixelRatio > 1.5) {
          current.width = current.width / pixelRatio;
          current.height = current.height / pixelRatio;
        }
      }
    });
    $('[data-fancybox="images-2"]').fancybox({
      afterLoad: function (instance: any, current: any) {
        const pixelRatio = window.devicePixelRatio || 1;

        if (pixelRatio > 1.5) {
          current.width = current.width / pixelRatio;
          current.height = current.height / pixelRatio;
        }
      }
    });
    $('[data-fancybox="images-3"]').fancybox({
      afterLoad: function (instance: any, current: any) {
        const pixelRatio = window.devicePixelRatio || 1;

        if (pixelRatio > 1.5) {
          current.width = current.width / pixelRatio;
          current.height = current.height / pixelRatio;
        }
      }
    });
    $('[data-fancybox="images-4"]').fancybox({
      afterLoad: function (instance: any, current: any) {
        const pixelRatio = window.devicePixelRatio || 1;

        if (pixelRatio > 1.5) {
          current.width = current.width / pixelRatio;
          current.height = current.height / pixelRatio;
        }
      }
    });
    $('[data-fancybox="images-5"]').fancybox({
      afterLoad: function (instance: any, current: any) {
        const pixelRatio = window.devicePixelRatio || 1;

        if (pixelRatio > 1.5) {
          current.width = current.width / pixelRatio;
          current.height = current.height / pixelRatio;
        }
      }
    });
    $('[data-fancybox="images-6"]').fancybox({
      afterLoad: function (instance: any, current: any) {
        const pixelRatio = window.devicePixelRatio || 1;

        if (pixelRatio > 1.5) {
          current.width = current.width / pixelRatio;
          current.height = current.height / pixelRatio;
        }
      }
    });
  }

}
