<div class="section-header section-parallax section-1">
  <app-social-media></app-social-media>
  <div class="main-content">
    <div class="caption">
      <p>
        Qualité et sobriété architecturale
        <br> Adaptation et convivialité des espaces
      </p>
    </div>
    <!--.caption-->
  </div>
  <!--.main-content-->
</div>
<!--.section-header-->

<section class="logements-area">
  <div class="main-content">
    <div class="back-link">
      <a routerLink="/nos-projets">Nos projets</a>
    </div>
    <div class="section_title black-style">
      <h1>nos projets et créations - Activités/Commerces/Bureaux</h1>
    </div>


    <div class="projects-items-area">
      <div class="project-item_first">
        <div class="project-item viking-color">
          <div class="project-item_desc">
            <div class="project-item_title">
              <h3>11 cours gabriel - chartres (28) salle de cours de cuisine et de receptions</h3>
            </div>
            <div class="project-item_bigImg">
              <img src="assets/img/activites-bureaux/article-7/article-7-4.jpg" alt="article-7-4">
            </div>
          </div>
          <!--.project-item_desc-->

          <div class="logement-item">
            <div class="logement-item-desc">
              <p>Opération : Réhabilitation d'un ancien garage salle de cours de cuisine</p>
              <p>Maître d'ouvrage : Le Grans Monarque</p>
              <p>Mission : Complète à 100%</p>
              <p>Montant des travaux : 350 000 € ht</p>
              <p>Surface : 220 m2 SP</p>
              <p>Date de livraisons : Février 2012</p>
            </div>
            <!--.logement-item-desc-->
            <div class="logement-lightbox">
              <div class="logement-lightbox_img">
                <a href="assets/img/activites-bureaux/article-7/article-7-2.jpg" data-fancybox="images">
                  <img src="assets/img/activites-bureaux/article-7/article-7-2.jpg" alt="article-7-2">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/activites-bureaux/article-7/article-7-3.jpg" data-fancybox="images">
                  <img src="assets/img/activites-bureaux/article-7/article-7-3.jpg" alt="article-7-3">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/activites-bureaux/article-7/article-7-4.jpg" data-fancybox="images">
                  <img src="assets/img/activites-bureaux/article-7/article-7-4.jpg" alt="article-7-4">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/activites-bureaux/article-7/article-7-5.jpg" data-fancybox="images">
                  <img src="assets/img/activites-bureaux/article-7/article-7-5.jpg" alt="article-7-5">
                </a>
              </div>
            </div>
            <!--.logement-lightbox-->
          </div>
          <!--.logement-item-->
        </div>
        <!--.project-item-->
        <div class="project-item viking-color ">
          <div class="project-item_desc">
            <div class="project-item_title">
              <h3>Construction de deux batiments commerciaux za la torche - commune de barjouville (28)</h3>
            </div>
            <div class="project-item_bigImg">
              <img src="assets/img/activites-bureaux/article-8/article-8-3.jpg" alt="article-8-3">
            </div>
          </div>
          <!--.project-item_desc-->

          <div class="logement-item">
            <div class="logement-item-desc">
              <p>Opération : Construction de deux bâtiments à usage commercial</p>
              <p>Maître d'ouvrage : Privé</p>
              <p>Mission : Conception à 30 %</p>
              <p>Montant des travaux : 5 000 000 € ht</p>
              <p>Surface : 12 000 m² SP</p>
              <p>Date de livraison : Décembre 2016</p>
            </div>
            <!--.logement-item-desc-->
            <div class="logement-lightbox">
              <div class="logement-lightbox_img">
                <a href="assets/img/activites-bureaux/article-8/article-8-1.jpg" data-fancybox="images-2">
                  <img src="assets/img/activites-bureaux/article-8/article-8-1.jpg" alt="article-8-1">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/activites-bureaux/article-8/article-8-2.jpg" data-fancybox="images-2">
                  <img src="assets/img/activites-bureaux/article-8/article-8-2.jpg" alt="article-8-2">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/activites-bureaux/article-8/article-8-3.jpg" data-fancybox="images-2">
                  <img src="assets/img/activites-bureaux/article-8/article-8-3.jpg" alt="article-8-3">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/activites-bureaux/article-8/article-8-4.jpg" data-fancybox="images-2">
                  <img src="assets/img/activites-bureaux/article-8/article-8-4.jpg" alt="article-8-4">
                </a>
              </div>
            </div>
            <!--.logement-lightbox-->
          </div>
          <!--.logement-item-->
        </div>
        <!--.project-item-->
      </div>
      <!--.project-item_first-->
      <div class="project-item_second">
        <div class="project-item viking-color">
          <div class="project-item_desc">
            <div class="project-item_title">
              <h3>Transformation d'une maison de maitre en hotel 4 etoiles dreux (28)</h3>
            </div>
            <div class="project-item_bigImg">
              <img src="assets/img/activites-bureaux/article-9/article-9-1.jpg" alt="article-9-1">
            </div>
          </div>
          <!--.project-item_desc-->
          <div class="logement-item">
            <div class="logement-item-desc">
              <p>Opération : Création d'un hôtel 4 étoiles à Dreux</p>
              <p>Maître d'ouvrage : Privé</p>
              <p>Mission : Conception à 100%</p>
              <p>Montant des travaux : 600 000,00 € ht</p>
              <p>Surface : 703 m2</p>
              <p>Date de livraison : courant 2016</p>
            </div>
            <!--.logement-item-desc-->
            <div class="logement-lightbox">
              <div class="logement-lightbox_img">
                <a href="assets/img/activites-bureaux/article-9/article-9-1.jpg" data-fancybox="images-3">
                  <img src="assets/img/activites-bureaux/article-9/article-9-1.jpg" alt="article-9-1">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/activites-bureaux/article-9/article-9-2.jpg" data-fancybox="images-3">
                  <img src="assets/img/activites-bureaux/article-9/article-9-2.jpg" alt="article-9-2">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/activites-bureaux/article-9/article-9-3.jpg" data-fancybox="images-3">
                  <img src="assets/img/activites-bureaux/article-9/article-9-3.jpg" alt="article-9-3">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/activites-bureaux/article-9/article-9-4.jpg" data-fancybox="images-3">
                  <img src="assets/img/activites-bureaux/article-9/article-9-4.jpg" alt="article-9-4">
                </a>
              </div>
            </div>
            <!--.logement-lightbox-->
          </div>
          <!--.logement-item-->

        </div>
        <!--.project-item-->
        <div class="project-item viking-color">
          <div class="project-item_desc">
            <div class="project-item_title">
              <h3>Batiment de bureaux - anet (28)</h3>
            </div>
            <div class="project-item_bigImg">
              <img src="assets/img/activites-bureaux/article-10/article-10-1.jpg" alt="article-10-1">
            </div>
          </div>
          <!--.project-item_desc-->
          <div class="logement-item">
            <div class="logement-item-desc">
              <p>Opération : Construction d'un bâtiment de bureaux</p>
              <p>Maître d'ouvrage : SCI I CONCEPT</p>
              <p>Mission : Conception à 100%</p>
              <p>Montant des travaux : 1 400 000 € ht</p>
              <p>Surface : 1394 m2 SHON</p>
              <p>Date de livraison : Février 2012</p>
            </div>
            <!--.logement-item-desc-->
            <div class="logement-lightbox">
              <div class="logement-lightbox_img">
                <a href="assets/img/activites-bureaux/article-10/article-10-1.jpg" data-fancybox="images-4">
                  <img src="assets/img/activites-bureaux/article-10/article-10-1.jpg" alt="article-10-1">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/activites-bureaux/article-10/article-10-2.jpg" data-fancybox="images-4">
                  <img src="assets/img/activites-bureaux/article-10/article-10-2.jpg" alt="article-10-2">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/activites-bureaux/article-10/article-10-3.jpg" data-fancybox="images-4">
                  <img src="assets/img/activites-bureaux/article-10/article-10-3.jpg" alt="article-10-3">
                </a>
              </div>
            </div>
            <!--.logement-lightbox-->
          </div>
          <!--.logement-item-->

        </div>
        <!--.project-item-->
        <div class="project-item viking-color">
          <div class="project-item_desc">
            <div class="project-item_title">
              <h3>Rehabilitation d'une imprimerie en laboratoire de chocolaterie - sceaux (92)</h3>
            </div>
            <div class="project-item_bigImg">
              <img src="assets/img/activites-bureaux/article-11/article-11-1.jpg" alt="article-11-1">
            </div>
          </div>
          <!--.project-item_desc-->
          <div class="logement-item">

            <div class="logement-item-desc">
              <p>Opération : Réhabilitation d'un local imprimerie en laboratoire de chocolaterie</p>
              <p>Maître d'ouvrage : Patrick Roger et Cie</p>
              <p>Mission : Conception à 100%</p>
              <p>Montant des travaux : 1 100 000 € ht</p>
              <p>Surface : 1200 m2</p>
              <p>Date de livraison : Août 2008</p>
            </div>
            <!--.logement-item-desc-->
            <div class="logement-lightbox">
              <div class="logement-lightbox_img">
                <a href="assets/img/activites-bureaux/article-11/article-11-1.jpg" data-fancybox="images-5">
                  <img src="assets/img/activites-bureaux/article-11/article-11-1.jpg" alt="article-11-1">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/activites-bureaux/article-11/article-11-2.jpg" data-fancybox="images-5">
                  <img src="assets/img/activites-bureaux/article-11/article-11-2.jpg" alt="article-11-2">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/activites-bureaux/article-11/article-11-3.jpg" data-fancybox="images-5">
                  <img src="assets/img/activites-bureaux/article-11/article-11-3.jpg" alt="article-11-3">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/activites-bureaux/article-11/article-11-4.jpg" data-fancybox="images-5">
                  <img src="assets/img/activites-bureaux/article-11/article-11-4.jpg" alt="article-11-4">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/activites-bureaux/article-11/article-11-5.jpg" data-fancybox="images-5">
                  <img src="assets/img/activites-bureaux/article-11/article-11-5.jpg" alt="article-11-5">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/activites-bureaux/article-11/article-11-6.jpg" data-fancybox="images-5">
                  <img src="assets/img/activites-bureaux/article-11/article-11-6.jpg" alt="article-11-6">
                </a>
              </div>
            </div>
            <!--.logement-lightbox-->
          </div>
          <!--.logement-item-->

        </div>
        <!--.project-item-->
        <div class="project-item viking-color">
          <div class="project-item_desc">
            <div class="project-item_title">
              <h3>immeuble de bureaux - chartres (28)</h3>
            </div>
            <div class="project-item_bigImg">
              <img src="assets/img/activites-bureaux/article-12/article-12-1.jpg" alt="article-12-1">
            </div>
          </div>
          <!--.project-item_desc-->
          <div class="logement-item">
            <div class="logement-item-desc">
              <p>Opération : Immeuble de bureaux</p>
              <p>Maître d'ouvrage : Victoria Image et Son</p>
              <p>Mission : complète à 100 % OPC détachée</p>
              <p>Montant des travaux : 1 156 000 € ht</p>
              <p>SHON : 995 m2</p>
              <p>Date de livraison : Mai 2012</p>
            </div>
            <!--.logement-item-desc-->
            <div class="logement-lightbox">
              <div class="logement-lightbox_img">
                <a href="assets/img/activites-bureaux/article-12/article-12-1.jpg" data-fancybox="images-6">
                  <img src="assets/img/activites-bureaux/article-12/article-12-1.jpg" alt="article-12-1">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/activites-bureaux/article-12/article-12-2.jpg" data-fancybox="images-6">
                  <img src="assets/img/activites-bureaux/article-12/article-12-2.jpg" alt="article-12-2">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/activites-bureaux/article-12/article-12-3.jpg" data-fancybox="images-6">
                  <img src="assets/img/activites-bureaux/article-12/article-12-3.jpg" alt="article-12-3">
                </a>
              </div>
            </div>
            <!--.logement-lightbox-->
          </div>
          <!--.logement-item-->

        </div>
        <!--.project-item-->
      </div>
      <!--.project-item_second-->
    </div>
    <!--.projects-items-area-->


  </div>
  <!--.main-content-->
</section>
<!--.logements-area-->