import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-commerces',
  templateUrl: './commerces.component.html',
  styleUrls: ['./commerces.component.scss']
})
export class CommercesComponent implements OnInit {
  oneAtATime: boolean = true;
  isFirstOpen: boolean = true;
  constructor() { }

  ngOnInit(): void {
  }

  fancyboxFct(): void {
    $('[data-fancybox="images"]').fancybox({
      afterLoad: function (instance: any, current: any) {
        const pixelRatio = window.devicePixelRatio || 1;

        if (pixelRatio > 1.5) {
          current.width = current.width / pixelRatio;
          current.height = current.height / pixelRatio;
        }
      }
    });
  }

}
