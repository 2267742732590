<div class="social-media">
    <a class="icon facebook-icn">
        <div class="social-media-desc link-1">
            <a  href="https://www.facebook.com/MSArchitecture28/" target="_blank">MSArchitecture28</a>
        </div>
    </a>
    <!--<a class="icon instagram-icn">
        <div class="social-media-desc link-2">
            <a>instagram link</a>
        </div>
    </a>-->
    <a class="icon linkedin-icn">
        <div class="social-media-desc link-3">
            <a href="https://www.linkedin.com/in/yann-sidoroff-01294411/" target="_blank">linkedin link</a>
        </div>
    </a>
    <a class="icon call-icn">
        <div class="social-media-desc link-4">
            02 37 21 49 26
        </div>
    </a>
    <!--<a class="icon phone-icn">
        <div class="social-media-desc link-5">
            06 27 25 02 30
        </div>
    </a>-->
    <a class="icon mail-icn">
        <div class="social-media-desc link-6">
            <a href="mailto:ysidoroff@ms-architecture.fr">ysidoroff@ms-architecture.fr</a>
        </div>
    </a>
</div>
<!--.social-media-->
