// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/**
 * Base name server api
 * default: localhost
 * replaced by server dns when deployed
 * example: www.myserver.com
 */
const BASE_API = 'vps-169508f5.vps.ovh.net';


/**
 * API port
 * default: 8000
 * replaced by '' when deployed
 */
const API_PORT = '8080';

/**
 *  HTTP/HTTPS usage
 *
 *  default false
 *  replaced by true if deployed on ssl supported server
 */
const API_SSL = false;

/**
 * http protocol usage by ssl value.
 */
const HTTP_PROTOCOL = API_SSL?'https://':'http://';

/**
 * default server path
 * default: '/'
 * it can be another it depends on deployment configurations
 * example:
 *  '/api/'
 *  '/server/'
 *  etc..
 */
const ALIAS_PATH = '/';

export const environment = {
  production: false,
  // by default: http://localhost:8000/
  api: `${HTTP_PROTOCOL}${BASE_API}${API_PORT?':'+API_PORT:''}${ALIAS_PATH}`,
  cookieDomain: BASE_API,
  cookieUrl: 'http://' + BASE_API + '/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
