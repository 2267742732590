<div class="section-header section-parallax section-1">
    <app-social-media></app-social-media>
    <div class="main-content">
        <div class="caption">
            <p>
                Qualité et sobriété architecturale
                <br> Adaptation et convivialité des espaces
            </p>
        </div>
        <!--.caption-->
    </div>
    <!--.main-content-->
</div>
<!--.section-header-->


<section class="logements-area">
    <div class="main-content">
        <div class="section_title black-style">
            <h1>nos projets et créations - Hôtellerie</h1>
        </div>

        <div class="accordion-area">
            <accordion [closeOthers]="oneAtATime" [isAnimated]="true">
                <accordion-group heading="Hôtel Mercure" [isOpen]="isFirstOpen">

                    <div class="logement-item">
                        <div class="logement-item-desc">
                            <p>
                                L’hôtel Mercure de Chartres-Barjouville a confié à l’Agence la réfection complète de sa façade et la mise au normes PMR de
                                l’établissement.
                            </p>
                            <p>
                                Ce projet se traduit pas la réfection complète de l’accueil et de l’ajout d’un ascenseur en façade permettant l’accès aux
                                étages des personnes à mobilité réduite.
                            </p>
                        </div>
                        <!--.logement-item-desc-->

                        <div class="logement-lightbox">
                            <div class="logement-lightbox_img">
                                <a href="assets/img/hotellerie/article-1/mercure.jpg" data-fancybox="images">
                                    <img src="assets/img/hotellerie/article-1/mercure_m.jpg" alt="mercure_m">
                                </a>
                            </div>
                        </div>
                        <!--.logement-lightbox-->
                    </div>
                    <!--.logement-item-->

                </accordion-group>

            </accordion>
        </div>
        <!--.accordion-area-->

    </div>
    <!--.main-content-->
</section>
<!--.logements-area-->