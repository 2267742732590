
/**
 * Base name server api
 * default: localhost
 * replaced by server dns when deployed
 * example: www.myserver.com
 */
const BASE_API = 'localhost';

/**
 * API port
 * default: 8000
 * replaced by '' when deployed
 * replaced by '' when deployed
 */
const API_PORT = '8000';

/**
 *  HTTP/HTTPS usage
 *
 *  default false
 *  replaced by true if deployed on ssl supported server
 */
const API_SSL = false;

/**
 * http protocol usage by ssl value.
 */
const HTTP_PROTOCOL = API_SSL?'https://':'http://';

/**
 * default server path
 * default: '/'
 * it can be another it depends on deployment configurations
 * example:
 *  '/api/'
 *  '/server/'
 *  etc..
 */
const ALIAS_PATH = '/';

export const environment = {
  production: true,
  api: `${HTTP_PROTOCOL}${BASE_API}${API_PORT?':'+API_PORT:''}${ALIAS_PATH}`,
  cookieDomain: BASE_API,
  cookieUrl: 'http://' + BASE_API + '/'
};
