import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-logements',
  templateUrl: './logements.component.html',
  styleUrls: ['./logements.component.scss']
})
export class LogementsComponent implements OnInit {
  oneAtATime: boolean = true;
  isFirstOpen: boolean = true;
  constructor() { }

  ngOnInit(): void {
    this.projectItemEvent();
  }


  projectItemEvent(): void {
    $('.project-item_desc').bind('click', (event: any) => {
      $('.project-item').removeClass('active');
      setTimeout(() => {
        $(event.currentTarget).parent('.project-item').addClass('active');
      }, 400);
    });
  }

  fancyboxFct(): void {
    $('[data-fancybox="images-1"]').fancybox({
      afterLoad: function (instance: any, current: any) {
        const pixelRatio = window.devicePixelRatio || 1;

        if (pixelRatio > 1.5) {
          current.width = current.width / pixelRatio;
          current.height = current.height / pixelRatio;
        }
      }
    });
    $('[data-fancybox="images-2"]').fancybox({
      afterLoad: function (instance: any, current: any) {
        const pixelRatio = window.devicePixelRatio || 1;

        if (pixelRatio > 1.5) {
          current.width = current.width / pixelRatio;
          current.height = current.height / pixelRatio;
        }
      }
    });
    $('[data-fancybox="images-3"]').fancybox({
      afterLoad: function (instance: any, current: any) {
        const pixelRatio = window.devicePixelRatio || 1;

        if (pixelRatio > 1.5) {
          current.width = current.width / pixelRatio;
          current.height = current.height / pixelRatio;
        }
      }
    });
    $('[data-fancybox="images-4"]').fancybox({
      afterLoad: function (instance: any, current: any) {
        const pixelRatio = window.devicePixelRatio || 1;

        if (pixelRatio > 1.5) {
          current.width = current.width / pixelRatio;
          current.height = current.height / pixelRatio;
        }
      }
    });
    $('[data-fancybox="images-5"]').fancybox({
      afterLoad: function (instance: any, current: any) {
        const pixelRatio = window.devicePixelRatio || 1;

        if (pixelRatio > 1.5) {
          current.width = current.width / pixelRatio;
          current.height = current.height / pixelRatio;
        }
      }
    });
    $('[data-fancybox="images-6"]').fancybox({
      afterLoad: function (instance: any, current: any) {
        const pixelRatio = window.devicePixelRatio || 1;

        if (pixelRatio > 1.5) {
          current.width = current.width / pixelRatio;
          current.height = current.height / pixelRatio;
        }
      }
    });
    $('[data-fancybox="images-7"]').fancybox({
      afterLoad: function (instance: any, current: any) {
        const pixelRatio = window.devicePixelRatio || 1;

        if (pixelRatio > 1.5) {
          current.width = current.width / pixelRatio;
          current.height = current.height / pixelRatio;
        }
      }
    });
    $('[data-fancybox="images-8"]').fancybox({
      afterLoad: function (instance: any, current: any) {
        const pixelRatio = window.devicePixelRatio || 1;

        if (pixelRatio > 1.5) {
          current.width = current.width / pixelRatio;
          current.height = current.height / pixelRatio;
        }
      }
    });
    $('[data-fancybox="images-9"]').fancybox({
      afterLoad: function (instance: any, current: any) {
        const pixelRatio = window.devicePixelRatio || 1;

        if (pixelRatio > 1.5) {
          current.width = current.width / pixelRatio;
          current.height = current.height / pixelRatio;
        }
      }
    });
    $('[data-fancybox="images-10"]').fancybox({
      afterLoad: function (instance: any, current: any) {
        const pixelRatio = window.devicePixelRatio || 1;

        if (pixelRatio > 1.5) {
          current.width = current.width / pixelRatio;
          current.height = current.height / pixelRatio;
        }
      }
    });
    $('[data-fancybox="images-11"]').fancybox({
      afterLoad: function (instance: any, current: any) {
        const pixelRatio = window.devicePixelRatio || 1;

        if (pixelRatio > 1.5) {
          current.width = current.width / pixelRatio;
          current.height = current.height / pixelRatio;
        }
      }
    });
    $('[data-fancybox="images-12"]').fancybox({
      afterLoad: function (instance: any, current: any) {
        const pixelRatio = window.devicePixelRatio || 1;

        if (pixelRatio > 1.5) {
          current.width = current.width / pixelRatio;
          current.height = current.height / pixelRatio;
        }
      }
    });
    $('[data-fancybox="images-13"]').fancybox({
      afterLoad: function (instance: any, current: any) {
        const pixelRatio = window.devicePixelRatio || 1;

        if (pixelRatio > 1.5) {
          current.width = current.width / pixelRatio;
          current.height = current.height / pixelRatio;
        }
      }
    });
    $('[data-fancybox="images-14"]').fancybox({
      afterLoad: function (instance: any, current: any) {
        const pixelRatio = window.devicePixelRatio || 1;

        if (pixelRatio > 1.5) {
          current.width = current.width / pixelRatio;
          current.height = current.height / pixelRatio;
        }
      }
    });
    $('[data-fancybox="images-15"]').fancybox({
      afterLoad: function (instance: any, current: any) {
        const pixelRatio = window.devicePixelRatio || 1;

        if (pixelRatio > 1.5) {
          current.width = current.width / pixelRatio;
          current.height = current.height / pixelRatio;
        }
      }
    });
    $('[data-fancybox="images-16"]').fancybox({
      afterLoad: function (instance: any, current: any) {
        const pixelRatio = window.devicePixelRatio || 1;

        if (pixelRatio > 1.5) {
          current.width = current.width / pixelRatio;
          current.height = current.height / pixelRatio;
        }
      }
    });
    $('[data-fancybox="images-17"]').fancybox({
      afterLoad: function (instance: any, current: any) {
        const pixelRatio = window.devicePixelRatio || 1;

        if (pixelRatio > 1.5) {
          current.width = current.width / pixelRatio;
          current.height = current.height / pixelRatio;
        }
      }
    });
    $('[data-fancybox="images-18"]').fancybox({
      afterLoad: function (instance: any, current: any) {
        const pixelRatio = window.devicePixelRatio || 1;

        if (pixelRatio > 1.5) {
          current.width = current.width / pixelRatio;
          current.height = current.height / pixelRatio;
        }
      }
    });
    $('[data-fancybox="images-19"]').fancybox({
      afterLoad: function (instance: any, current: any) {
        const pixelRatio = window.devicePixelRatio || 1;

        if (pixelRatio > 1.5) {
          current.width = current.width / pixelRatio;
          current.height = current.height / pixelRatio;
        }
      }
    });
    $('[data-fancybox="images-20"]').fancybox({
      afterLoad: function (instance: any, current: any) {
        const pixelRatio = window.devicePixelRatio || 1;

        if (pixelRatio > 1.5) {
          current.width = current.width / pixelRatio;
          current.height = current.height / pixelRatio;
        }
      }
    });
    $('[data-fancybox="images-21"]').fancybox({
      afterLoad: function (instance: any, current: any) {
        const pixelRatio = window.devicePixelRatio || 1;

        if (pixelRatio > 1.5) {
          current.width = current.width / pixelRatio;
          current.height = current.height / pixelRatio;
        }
      }
    });
    $('[data-fancybox="images-22"]').fancybox({
      afterLoad: function (instance: any, current: any) {
        const pixelRatio = window.devicePixelRatio || 1;

        if (pixelRatio > 1.5) {
          current.width = current.width / pixelRatio;
          current.height = current.height / pixelRatio;
        }
      }
    });
    $('[data-fancybox="images-23"]').fancybox({
      afterLoad: function (instance: any, current: any) {
        const pixelRatio = window.devicePixelRatio || 1;

        if (pixelRatio > 1.5) {
          current.width = current.width / pixelRatio;
          current.height = current.height / pixelRatio;
        }
      }
    });
    $('[data-fancybox="images-24"]').fancybox({
      afterLoad: function (instance: any, current: any) {
        const pixelRatio = window.devicePixelRatio || 1;

        if (pixelRatio > 1.5) {
          current.width = current.width / pixelRatio;
          current.height = current.height / pixelRatio;
        }
      }
    });
    $('[data-fancybox="images-25"]').fancybox({
      afterLoad: function (instance: any, current: any) {
        const pixelRatio = window.devicePixelRatio || 1;

        if (pixelRatio > 1.5) {
          current.width = current.width / pixelRatio;
          current.height = current.height / pixelRatio;
        }
      }
    });

  }

}
