<div class="section-header section-parallax section-1">
  <app-social-media></app-social-media>
  <div class="main-content">
    <div class="caption">
      <p>
        Qualité et sobriété architecturale
        <br> Adaptation et convivialité des espaces
      </p>
    </div>
    <!--.caption-->
  </div>
  <!--.main-content-->
</div>
<!--.section-header-->


<section class="logements-area">
  <div class="main-content">
    <div class="back-link">
      <a routerLink="/nos-projets">Nos projets</a>
    </div>
    <div class="section_title black-style">
      <h1>nos projets et créations - Équipements publics/Scolaire</h1>
    </div>


    <div class="projects-items-area">
      <div class="project-item_first">

        <div class="project-item olive-drab-color">
          <div class="project-item_desc">
            <div class="project-item_title">
              <h3>CONSTRUCTION D'UN CENTRE DE LOISIRS SANS HEBERGEMENT - 28 - LE COUDRAY</h3>
            </div>
            <div class="project-item_bigImg">
              <img src="assets/img/equip-publiques/article-47/1.jpg" alt="">
            </div>
          </div>
          <!--.project-item_desc-->

          <div class="logement-item">
            <div class="logement-item-desc">
              <p>Opération : Construction d'un centre de loisirs sans hébergement RT2012</p>
              <p>Maître d'ouvrage : Mairie du Coudray (28)</p>
              <p>Mission : Complète à 100%</p>
              <p>Montant des travaux : 1 390 000,00 € ht</p>
              <p>Surface : 311 m2 SP</p>
              <p>Date de livraison : Septembre 2021</p>
            </div>
            <!--.logement-item-desc-->
            <div class="logement-lightbox">
              <div class="logement-lightbox_img">
                <a href="assets/img/equip-publiques/article-47/1.jpg" data-fancybox="images-47">
                  <img src="assets/img/equip-publiques/article-47/1.jpg" alt="agence-1">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/equip-publiques/article-47/2.jpg" data-fancybox="images-47">
                  <img src="assets/img/equip-publiques/article-47/2.jpg" alt="agence-2">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/equip-publiques/article-47/3.jpg" data-fancybox="images-47">
                  <img src="assets/img/equip-publiques/article-47/3.jpg" alt="agence-3">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/equip-publiques/article-47/4.jpg" data-fancybox="images-47">
                  <img src="assets/img/equip-publiques/article-47/4.jpg" alt="agence-4">
                </a>
              </div>
            </div>
            <!--.logement-lightbox-->
          </div>
          <!--.logement-item-->
        </div>
        <!--.project-item-->

        <div class="project-item olive-drab-color">
          <div class="project-item_desc">
            <div class="project-item_title">
              <h3>Construction d'un restaurant scolaire et de salles d'activites - commune du gros theil (27)</h3>
            </div>
            <div class="project-item_bigImg">
              <img src="assets/img/equip-publiques/article-7/article-7-1.jpg" alt="article-7-1">
            </div>
          </div>
          <!--.project-item_desc-->

          <div class="logement-item">
            <div class="logement-item-desc">
              <p>Opération : Construction d'une cantine et de salles d'activités BBC</p>
              <p>Maître d'ouvrage : Mairie du Bosc Du Theil (27)</p>
              <p>Mission : Complète à 100%</p>
              <p>Montant des travaux : 1 250 000 € ht</p>
              <p>Surface : 729 m2 SHON</p>
              <p>Date de livraison : Juillet 2017</p>
              <p>Chauffage : chaudière à granulés</p>
            </div>
            <!--.logement-item-desc-->
            <div class="logement-lightbox">
              <div class="logement-lightbox_img">
                <a href="assets/img/equip-publiques/article-7/article-7-1.jpg" data-fancybox="images-1">
                  <img src="assets/img/equip-publiques/article-7/article-7-1.jpg" alt="article-7-1">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/equip-publiques/article-7/article-7-2.jpg" data-fancybox="images-1">
                  <img src="assets/img/equip-publiques/article-7/article-7-2.jpg" alt="article-7-2">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/equip-publiques/article-7/article-7-3.jpg" data-fancybox="images-1">
                  <img src="assets/img/equip-publiques/article-7/article-7-3.jpg" alt="article-7-3">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/equip-publiques/article-7/article-7-4.jpg" data-fancybox="images-1">
                  <img src="assets/img/equip-publiques/article-7/article-7-4.jpg" alt="article-7-4">
                </a>
              </div>
            </div>
            <!--.logement-lightbox-->
          </div>
          <!--.logement-item-->
        </div>
        <!--.project-item-->
        
      </div>
      <!--.project-item_first-->
      <div class="project-item_second">

        <div class="project-item olive-drab-color ">
          <div class="project-item_desc">
            <div class="project-item_title">
              <h3>Construction d'une maison medicale le bosc du theil - 27</h3>
            </div>
            <div class="project-item_bigImg">
              <img src="assets/img/equip-publiques/article-8/article-8-1.jpg" alt="article-8-1">
            </div>
          </div>
          <!--.project-item_desc-->

          <div class="logement-item">
            <div class="logement-item-desc">
              <p>Opération : Construction d'une maison médicale</p>
              <p>Maître d'ouvrage : Mairie du Bosc du Theil (27)</p>
              <p>Mission : Complète à 100%</p>
              <p>Montant des travaux : 450 000 € ht</p>
              <p>Surface : 220 m2</p>
              <p>Date de livraison : Décembre 2021</p>
            </div>
            <!--.logement-item-desc-->

            <div class="logement-lightbox">
              <div class="logement-lightbox_img">
                <a href="assets/img/equip-publiques/article-8/article-8-1.jpg" data-fancybox="images-2">
                  <img src="assets/img/equip-publiques/article-8/article-8-1.jpg" alt="article-8-1">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/equip-publiques/article-8/article-8-2.jpg" data-fancybox="images-2">
                  <img src="assets/img/equip-publiques/article-8/article-8-2.jpg" alt="article-8-2">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/equip-publiques/article-8/article-8-3.jpg" data-fancybox="images-2">
                  <img src="assets/img/equip-publiques/article-8/article-8-3.jpg" alt="article-8-3">
                </a>
              </div>
            </div>
            <!--.logement-lightbox-->
          </div>
          <!--.logement-item-->
        </div>
        <!--.project-item-->

        <div class="project-item olive-drab-color">
          <div class="project-item_desc">
            <div class="project-item_title">
              <h3>Extension du groupe scolaire guy de maupassant commune de bourg-achard - 27</h3>
            </div>
            <div class="project-item_bigImg">
              <img src="assets/img/equip-publiques/article-9/article-9-1.jpg" alt="article-9-1">
            </div>
          </div>
          <!--.project-item_desc-->
          <div class="logement-item">

            <div class="logement-item-desc">
              <p>Opération : Extension du groupe scolaire 3 classes, ateliers, sanitaires</p>
              <p>Maître d'ouvrage : Commune de Bourg-Achard</p>
              <p>Mission : Complète à 100%</p>
              <p>Montant des travaux : 437 780,00 € ht</p>
              <p>Surface de plancher : 302 m²</p>
              <p>Date de livraison : 1er Trimestre 2017</p>
            </div>
            <!--.logement-item-desc-->

            <div class="logement-lightbox">
              <div class="logement-lightbox_img">
                <a href="assets/img/equip-publiques/article-9/article-9-1.jpg" data-fancybox="images-3">
                  <img src="assets/img/equip-publiques/article-9/article-9-1.jpg" alt="article-9-1">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/equip-publiques/article-9/article-9-2.jpg" data-fancybox="images-3">
                  <img src="assets/img/equip-publiques/article-9/article-9-2.jpg" alt="article-9-2">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/equip-publiques/article-9/article-9-3.jpg" data-fancybox="images-3">
                  <img src="assets/img/equip-publiques/article-9/article-9-3.jpg" alt="article-9-3">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/equip-publiques/article-9/article-9-4.jpg" data-fancybox="images-3">
                  <img src="assets/img/equip-publiques/article-9/article-9-4.jpg" alt="article-9-4">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/equip-publiques/article-9/article-9-5.jpg" data-fancybox="images-3">
                  <img src="assets/img/equip-publiques/article-9/article-9-5.jpg" alt="article-9-5">
                </a>
              </div>
            </div>
            <!--.logement-lightbox-->
          </div>
          <!--.logement-item-->

        </div>
        <!--.project-item-->
        <div class="project-item olive-drab-color">
          <div class="project-item_desc">
            <div class="project-item_title">
              <h3>Construction d'une salle des fetes commune de francheville (27)</h3>
            </div>
            <div class="project-item_bigImg">
              <img src="assets/img/equip-publiques/article-10/article-10-1.jpg" alt="article-10-1">
            </div>
          </div>
          <!--.project-item_desc-->
          <div class="logement-item">

            <div class="logement-item-desc">
              <p>Opération : Salle des fêtes - BBC Eiffenergie</p>
              <p>Maître d'ouvrage : Commune de Francheville</p>
              <p>Mission : Complète à 100%</p>
              <p>Montant des travaux : 970 000,00 € ht</p>
              <p>Surface : 600 m2 SP</p>
              <p>Date de livraison : Juillet 2017</p>
            </div>
            <!--.logement-item-desc-->
            <div class="logement-lightbox">
              <div class="logement-lightbox_img">
                <a href="assets/img/equip-publiques/article-10/article-10-1.jpg" data-fancybox="images-4">
                  <img src="assets/img/equip-publiques/article-10/article-10-1.jpg" alt="article-10-1">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/equip-publiques/article-10/article-10-2.jpg" data-fancybox="images-4">
                  <img src="assets/img/equip-publiques/article-10/article-10-2.jpg" alt="article-10-2">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/equip-publiques/article-10/article-10-3.jpg" data-fancybox="images-4">
                  <img src="assets/img/equip-publiques/article-10/article-10-3.jpg" alt="article-10-3">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/equip-publiques/article-10/article-10-4.jpg" data-fancybox="images-4">
                  <img src="assets/img/equip-publiques/article-10/article-10-4.jpg" alt="article-10-4">
                </a>
              </div>

            </div>
            <!--.logement-lightbox-->
          </div>
          <!--.logement-item-->

        </div>
        <!--.project-item-->
        <div class="project-item olive-drab-color">
          <div class="project-item_desc">
            <div class="project-item_title">
              <h3>Construction d'une classe et extension du refectoire - mairie de goupillieres (27)</h3>
            </div>
            <div class="project-item_bigImg">
              <img src="assets/img/equip-publiques/article-11/article-11-2.jpg" alt="article-11-2">
            </div>
          </div>
          <!--.project-item_desc-->
          <div class="logement-item">

            <div class="logement-item-desc">
              <p>Opération : Construction d'une classe et extension du réfectoire</p>
              <p>Maître d'ouvrage : Mairie de Goupillières (27)</p>
              <p>Mission : Complète à 100%</p>
              <p>Montant des travaux : 395 603 € ht</p>
              <p>Surface : 337 m2 SHON</p>
              <p>Date de livraison : Juillet 2013 - Bâtiment livré</p>
            </div>
            <!--.logement-item-desc-->

            <div class="logement-lightbox">
              <div class="logement-lightbox_img">
                <a href="assets/img/equip-publiques/article-11/article-11-1.jpg" data-fancybox="images-5">
                  <img src="assets/img/equip-publiques/article-11/article-11-1.jpg" alt="article-11-1">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/equip-publiques/article-11/article-11-2.jpg" data-fancybox="images-5">
                  <img src="assets/img/equip-publiques/article-11/article-11-2.jpg" alt="article-11-2">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/equip-publiques/article-11/article-11-3.jpg" data-fancybox="images-5">
                  <img src="assets/img/equip-publiques/article-11/article-11-3.jpg" alt="article-11-3">
                </a>
              </div>
            </div>
            <!--.logement-lightbox-->
          </div>
          <!--.logement-item-->

        </div>
        <!--.project-item-->
        <div class="project-item olive-drab-color">
          <div class="project-item_desc">
            <div class="project-item_title">
              <h3>Construction de vestiaires/ tribunes stade jean boudrie commune de luce - 28</h3>
            </div>
            <div class="project-item_bigImg">
              <img src="assets/img/equip-publiques/article-12/article-12-2.jpg" alt="article-12-2">
            </div>
          </div>
          <!--.project-item_desc-->
          <div class="logement-item">

            <div class="logement-item-desc">
              <p>Opération : Construction de vestiaires / tribunes satde Jean Boudrie</p>
              <p>Maître d'ouvrage : Mairie de Lucé</p>
              <p>Mission : Complète à 100%</p>
              <p>Montant des travaux : 1 000 000 € ht</p>
              <p>Date de livraison : 12/2021</p>
            </div>
            <!--.logement-item-desc-->

            <div class="logement-lightbox">
              <div class="logement-lightbox_img">
                <a href="assets/img/equip-publiques/article-12/article-12-1.jpg" data-fancybox="images-6">
                  <img src="assets/img/equip-publiques/article-12/article-12-1.jpg" alt="article-12-1">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/equip-publiques/article-12/article-12-2.jpg" data-fancybox="images-6">
                  <img src="assets/img/equip-publiques/article-12/article-12-2.jpg" alt="article-12-2">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/equip-publiques/article-12/article-12-3.jpg" data-fancybox="images-6">
                  <img src="assets/img/equip-publiques/article-12/article-12-3.jpg" alt="article-12-3">
                </a>
              </div>

            </div>
            <!--.logement-lightbox-->
          </div>
          <!--.logement-item-->

        </div>
        <!--.project-item-->

      </div>
      <!--.project-item_second-->
    </div>
    <!--.projects-items-area-->


  </div>
  <!--.main-content-->
</section>
<!--.logements-area-->