<div class="section-header section-parallax section-1">
    <app-social-media></app-social-media>
    <div class="main-content">
        <div class="caption">
            <p>
                Qualité et sobriété architecturale
                <br> Adaptation et convivialité des espaces
            </p>
        </div>
        <!--.caption-->
    </div>
    <!--.main-content-->
</div>
<!--.section-header-->


<section class="logements-area">
    <div class="main-content">
        <div class="section_title black-style">
            <h1>nos projets et créations - Commerces</h1>
        </div>

        <div class="accordion-area">
            <accordion [closeOthers]="oneAtATime" [isAnimated]="true">
                <accordion-group heading="Boutiques Patrick Roger" [isOpen]="isFirstOpen">

                    <div class="logement-item">
                        <div class="logement-item-desc">
                            <p>
                                Patrick Roger a missionné l’Agence pour la réfection et la création de trois boutiques de vente de chocolats de luxe à Paris
                                et en région parisiennes, fief historique de ce génie gastronomique.
                            </p>
                            <p>
                                Les boutiques sont en activité depuis plus de deux ans.
                            </p>
                        </div>
                        <!--.logement-item-desc-->

                        <div class="logement-lightbox">
                            <div class="logement-lightbox_img">
                                <a href="assets/img/commerces/article-1/rennes.jpg" data-fancybox="images">
                                    <img src="assets/img/commerces/article-1/rennes_m.jpg" alt="rennes_m">
                                </a>
                            </div>
                            <div class="logement-lightbox_img">
                                <a href="assets/img/commerces/article-1/rennes2.jpg" data-fancybox="images">
                                    <img src="assets/img/commerces/article-1/rennes2_m.jpg" alt="rennes2_m">
                                </a>
                            </div>
                            <div class="logement-lightbox_img">
                                <a href="assets/img/commerces/article-1/rennes3.jpg" data-fancybox="images">
                                    <img src="assets/img/commerces/article-1/rennes3_m.jpg" alt="rennes3_m">
                                </a>
                            </div>
                            <div class="logement-lightbox_img">
                                <a href="assets/img/commerces/article-1/faubourg.jpg" data-fancybox="images">
                                    <img src="assets/img/commerces/article-1/faubourg_m.jpg" alt="faubourg_m">
                                </a>
                            </div>

                            <div class="logement-lightbox_img">
                                <a href="assets/img/commerces/article-1/sceaux.jpg" data-fancybox="images">
                                    <img src="assets/img/commerces/article-1/sceaux_m.jpg" alt="sceaux_m">
                                </a>
                            </div>
                        </div>
                        <!--.logement-lightbox-->
                    </div>
                    <!--.logement-item-->

                </accordion-group>

            </accordion>
        </div>
        <!--.accordion-area-->

    </div>
    <!--.main-content-->
</section>
<!--.logements-area-->