<div class="section-header section-parallax section-1 img-section-12">
    <app-social-media></app-social-media>
    <div class="main-content">
        <div class="caption">
            <p>
                Qualité et sobriété architecturale
                <br> Adaptation et convivialité des espaces
            </p>
        </div>
        <!--.caption-->
    </div>
    <!--.main-content-->
</div>
<!--.section-header-->

<section class="mes-projets-area">
    <div class="main-content">
        <div class="section_title black-style">
            <h1>nos projets et créations</h1>
        </div>

        <div class="nos-projets-desc">
            <p>
                L'agence MS Architecture, forte de l'expérience de son Architecte fondateur travaille dans des domaines très variés que sont:
                <!--<br> Le commerce, l'industrie, le logement ou encore le tertiaire.-->
            </p>
            <ul>
                <li>Le Logement</li>
                <li>Le Commerce</li>
                <li>Le Tertiaire</li>
            </ul>
            <p>
                Solidement ancrée en région centre, elle s'est vue confier des missions dans plusieurs départements limitrophes.
            </p>
            <p>
                Voici quelques exemples de projets en cours de réalisation ou livrés, gage du sérieux et de la diversité de l'Agence.
            </p>
        </div>
        <!--.nos-projets-desc-->


        <div class="projets-slide-group">
            <div class="grid-style-3 gap">
                <div class="grid-blc">
                    <div class="projets-slide-blc harvest-color" routerLink="/logements">
                        <div class="projets-slide_desc">
                            <h3>logements</h3>
                        </div>
                        <!--.projets-slide_desc-->

                        <div class="projets-slide_img">
                            <img src="assets/img/logements/logements.png" alt="logements">
                        </div>
                    </div>
                    <!--.projets-slide-blc-->
                </div>
                <div class="grid-blc">
                    <div class="projets-slide-blc viking-color" routerLink="/activites-bureaux">
                        <div class="projets-slide_desc">
                            <h3>Activités / Commerces / Bureaux</h3>
                        </div>
                        <!--.projets-slide_desc-->
                        <div class="projets-slide_img">
                            <img src="assets/img/activites-bureaux/article-11/article-11-6.jpg" alt="bureaux">
                        </div>
                    </div>
                    <!--.projets-slide-blc-->
                </div>

                <div class="grid-blc">
                    <div class="projets-slide-blc olive-drab-color" routerLink="/equip-publiques">
                        <div class="projets-slide_desc">
                            <h3>Équipements publics/Scolaire</h3>
                        </div>
                        <!--.projets-slide_desc-->
                        <div class="projets-slide_img">
                            <img src="assets/img/equip-publiques/equip-publiques.png" alt="equip-publiques">
                        </div>
                    </div>
                    <!--.projets-slide-blc-->
                </div>

            </div>
            <!--.grid-style-2-->
        </div>
        <!--.projets-slide-group-->


    </div>
    <!--.main-content-->
</section>
<!--.mes-projets-area-->