import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ContacterNousService {
  api = environment.api;
  constructor(
    private http: HttpClient
  ) { }

  /**
   * Send mail
   */
  sendMail(
    {
      from = '',
      body = '',
      firstname = '',
      lastname = ''
    } = {}
  ): Observable<HttpResponse<any>>{
    const requestBody = {
        from,
        body,
        firstname,
        lastname
    };
    return this.http.post(`${this.api}send-mail`,requestBody, {observe: 'response'});
  }
}
