import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mes-projets',
  templateUrl: './mes-projets.component.html',
  styleUrls: ['./mes-projets.component.scss']
})
export class MesProjetsComponent implements OnInit {

  projetsSlideConfigOne = {
    'slidesToShow': 1,
    'slidesToScroll': 1,
    'arrows': false,
    'autoplay': false,
    'autoplaySpeed': 3000,
    'centerPadding': '30px',
    'responsive': [
      {
        'breakpoint': 767,
        'settings': {
          'slidesToShow': 1,
          'slidesToScroll': 1
        }
      }
    ]
  };
  projetsSlideConfigTwo = {
    'slidesToShow': 1,
    'slidesToScroll': 1,
    'arrows': false,
    'autoplay': false,
    'autoplaySpeed': 4000,
    'centerPadding': '30px',
    'responsive': [
      {
        'breakpoint': 767,
        'settings': {
          'slidesToShow': 1,
          'slidesToScroll': 1
        }
      }
    ]
    /**/
  };
  projetsSlideConfigThree = {
    'slidesToShow': 1,
    'slidesToScroll': 1,
    'arrows': true,
    'autoplay': true,
    'autoplaySpeed': 3500,
    'centerPadding': '30px',
    'responsive': [
      {
        'breakpoint': 767,
        'settings': {
          'slidesToShow': 1,
          'slidesToScroll': 1
        }
      }
    ]
    /**/
  };
  projetsSlideConfigFour = {
    'slidesToShow': 1,
    'slidesToScroll': 1,
    'arrows': false,
    'autoplay': false,
    'autoplaySpeed': 4500,
    'centerPadding': '30px',
    'responsive': [
      {
        'breakpoint': 767,
        'settings': {
          'slidesToShow': 1,
          'slidesToScroll': 1
        }
      }
    ]
  };
  projetsSlideConfigFive = {
    'slidesToShow': 1,
    'slidesToScroll': 1,
    'arrows': true,
    'autoplay': true,
    'autoplaySpeed': 5500,
    'centerPadding': '30px',
    'responsive': [
      {
        'breakpoint': 767,
        'settings': {
          'slidesToShow': 1,
          'slidesToScroll': 1
        }
      }
    ]
  };
  projetsSlideConfigSix = {
    'slidesToShow': 1,
    'slidesToScroll': 1,
    'arrows': true,
    'autoplay': true,
    'autoplaySpeed': 6500,
    'centerPadding': '30px',
    'responsive': [
      {
        'breakpoint': 767,
        'settings': {
          'slidesToShow': 1,
          'slidesToScroll': 1
        }
      }
    ]
  };

  constructor() { }

  ngOnInit(): void {

  }




}
