import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ActualitesComponent } from './actualites/actualites.component';
import { ContactezNousComponent } from './contactez-nous/contactez-nous.component';
import { MesProjetsComponent } from './mes-projets/mes-projets.component';
import { LogementsComponent } from './logements/logements.component';
import { TertiaireComponent } from './tertiaire/tertiaire.component';
import { CommercesComponent } from './commerces/commerces.component';
import { EquipPubliquesComponent } from './equip-publiques/equip-publiques.component';
import { IndustrielComponent } from './industriel/industriel.component';
import { HotellerieComponent } from './hotellerie/hotellerie.component';
import { RestaurationComponent } from './restauration/restauration.component';
import { ActivitesBureauxComponent } from './activites-bureaux/activites-bureaux.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'actualites',
    component: ActualitesComponent
  },
  {
    path: 'contactez-nous',
    component: ContactezNousComponent
  },
  {
    path: 'nos-projets',
    component: MesProjetsComponent
  },
  {
    path: 'logements',
    component: LogementsComponent
  },
  {
    path: 'tertiaire',
    component: TertiaireComponent
  },
  {
    path: 'commerces',
    component: CommercesComponent
  },
  {
    path: 'equip-publiques',
    component: EquipPubliquesComponent
  },
  {
    path: 'industriel',
    component: IndustrielComponent
  },
  {
    path: 'hotellerie',
    component: HotellerieComponent
  },
  {
    path: 'restauration',
    component: RestaurationComponent
  },
  {
    path: 'activites-bureaux',
    component: ActivitesBureauxComponent
  }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
