import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ContacterNousService } from "../_services/contacter-nous.service";

declare const $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  projetsSlideGroupConfig = {
    'slidesToShow': 3,
    'arrows': true,
    'autoplay': false,
    'autoplaySpeed': 3000,
    'centerPadding': '60px',
    'responsive': [
      {
        'breakpoint': 1200,
        'settings': {
          'slidesToShow': 2,
          'slidesToScroll': 1,
          'centerPadding': '30px'
        }
      },
      {
        'breakpoint': 767,
        'settings': {
          'slidesToShow': 1,
          'slidesToScroll': 1,
          'autoplay': false,
          'centerPadding': '10px'
        }
      }
    ]
  };

  projetsSlideConfigOne = {
    'slidesToShow': 1,
    'slidesToScroll': 1,
    'arrows': true,
    'autoplay': true,
    'autoplaySpeed': 3000,
    'centerPadding': '30px',
    'responsive': [
      {
        'breakpoint': 767,
        'settings': {
          'slidesToShow': 1,
          'slidesToScroll': 1
        }
      }
    ]
  };
  projetsSlideConfigTwo = {
    'slidesToShow': 1,
    'slidesToScroll': 1,
    'arrows': false,
    'autoplay': false,
    'autoplaySpeed': 4000,
    'centerPadding': '30px',
    'responsive': [
      {
        'breakpoint': 767,
        'settings': {
          'slidesToShow': 1,
          'slidesToScroll': 1
        }
      }
    ]
    /**/
  };
  projetsSlideConfigThree = {
    'slidesToShow': 1,
    'slidesToScroll': 1,
    'arrows': true,
    'autoplay': true,
    'autoplaySpeed': 3500,
    'centerPadding': '30px',
    'responsive': [
      {
        'breakpoint': 767,
        'settings': {
          'slidesToShow': 1,
          'slidesToScroll': 1
        }
      }
    ]
    /**/
  };
  projetsSlideConfigFour = {
    'slidesToShow': 1,
    'slidesToScroll': 1,
    'arrows': true,
    'autoplay': true,
    'autoplaySpeed': 4500,
    'centerPadding': '30px',
    'responsive': [
      {
        'breakpoint': 767,
        'settings': {
          'slidesToShow': 1,
          'slidesToScroll': 1
        }
      }
    ]
  };
  projetsSlideConfigFive = {
    'slidesToShow': 1,
    'slidesToScroll': 1,
    'arrows': true,
    'autoplay': true,
    'autoplaySpeed': 5500,
    'centerPadding': '30px',
    'responsive': [
      {
        'breakpoint': 767,
        'settings': {
          'slidesToShow': 1,
          'slidesToScroll': 1
        }
      }
    ]
  };
  projetsSlideConfigSix = {
    'slidesToShow': 1,
    'slidesToScroll': 1,
    'arrows': false,
    'autoplay': false,
    'autoplaySpeed': 6500,
    'centerPadding': '30px',
    'responsive': [
      {
        'breakpoint': 767,
        'settings': {
          'slidesToShow': 1,
          'slidesToScroll': 1
        }
      }
    ]
  };
  actualiteSlideConfig = {
    'slidesToShow': 1,
    'slidesToScroll': 1,
    'arrows': true,
    'autoplay': true,
    'autoplaySpeed': 4000,
    'centerPadding': '30px',
    'responsive': [
      {
        'breakpoint': 1200,
        'settings': {
          'centerPadding': '10px'
        }
      },
      {
        'breakpoint': 767,
        'settings': {
          'slidesToShow': 1,
          'slidesToScroll': 1
        }
      }
    ]
    /**/
  };

  contactForm: any;
  isLoading = false;
  serverResponse: any = {
    hasError: function () {
      return this.errors.length > 0;
    },
    reset: function () {
      this.success = null;
      this.initialized = true;
      this.errors = [];
    },
    errors: [],
    success: null,
    initialized: true
  };
  submitted = false;
  subscription: any;

  constructor(
    private fb: FormBuilder,
    private contactService: ContacterNousService
  ) { }

  ngOnInit(): void {
    this.srollFct();

    const emailPattern = /^\w+[0-9\.\-\w]+@[0-9\-\w]+\.\w{2,3}$/
    this.contactForm = this.fb.group({
      from: ['', [Validators.required, Validators.pattern(emailPattern)]],
      body: [''],
      firstname: [''],
      lastname: ['']
    });
    this.subscription = this.contactForm.valueChanges
      .subscribe(
        (data: any) => {
          if (!this.serverResponse.initialized) {
            this.serverResponse.reset();
          }
        }
      );


  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }




  srollFct() {
    const scroll = window.requestAnimationFrame ||
      function (callback) { window.setTimeout(callback, 1000 / 60) };
    const elementsToShow = document.querySelectorAll('.sroll-animation');

    function loop() {

      elementsToShow.forEach(function (element) {
        if (isElementInViewport(element)) {
          element.classList.add('is-visible');
        } else {
          element.classList.remove('is-visible');
        }
      });

      scroll(loop);
    }

    loop();

    function isElementInViewport(el: any) {
      if (typeof $ === "function" && el instanceof $) {
        el = el[0];
      }
      var rect = el.getBoundingClientRect();
      return (
        (rect.top <= 0
          && rect.bottom >= 0)
        ||
        (rect.bottom >= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.top <= (window.innerHeight || document.documentElement.clientHeight))
        ||
        (rect.top >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight))
      );
    }
  }


  onSubmit() {
    this.submitted = true;
    if (this.contactForm.invalid) {
      return;
    }
    const body = this.contactForm.value;
    this.isLoading = true;
    this.contactService
      .sendMail(body)
      .subscribe(
        response => {
          if (response.body.error) {
            this.serverResponse.initialized = false;
            this.serverResponse.errors.push(response.body.error);
          } else {
            this.serverResponse.reset();
          }
          if (response.body.success) {
            this.serverResponse.success = true;
            this.serverResponse.initialized = false;
          }
          this.isLoading = false;
          this.submitted = false;
        },
        error => {
          this.isLoading = false;
          //this.serverResponse.initialized = false;
          this.serverResponse.reset();
          this.serverResponse.errors.push(error.error);
        }
      )
  }



}
