<div class="section-header section-parallax section-1">
  <app-social-media></app-social-media>
  <div class="main-content">
    <div class="caption">
      <p>
        Qualité et sobriété architecturale
        <br> Adaptation et convivialité des espaces
      </p>
    </div>
    <!--.caption-->
  </div>
  <!--.main-content-->
</div>
<!--.section-header-->


<section class="logements-area">
  <div class="main-content">
    <div class="back-link">
      <a routerLink="/nos-projets">Nos projets</a>
    </div>
    <div class="section_title black-style">
      <h1>nos projets et créations - Logements</h1>
    </div>

    <div class="projects-items-area">
      <div class="project-item_first">



        <div class="project-item harvest-color">
          <div class="project-item_desc">
            <div class="project-item_title">
              <h3>159 logements en accession sur deux sites commune de chartres (28)</h3>
            </div>
            <div class="project-item_bigImg">
              <img src="assets/img/logements/article-21/article-21-4-thumb.jpg" alt="">
            </div>
          </div>
          <!--.project-item_desc-->

          <div class="logement-item">
            <div class="logement-item-desc">
              <p>Opération : Construction de 159 logements</p>
              <p>Maître d'ouvrage : FIDUCIM</p>
              <p>Mission : Complète à 100%</p>
              <p>Montant des travaux : 12 520 000 € ht</p>
              <p>Surface : 11 800 m2 SHAB</p>
              <p>Date de livraison : 12/2022</p>
            </div>
            <!--.logement-item-desc-->
            <div class="logement-lightbox">
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-21/Plan_Masse_01_Editb.jpg" data-fancybox="images-1">
                  <img src="assets/img/logements/article-21/Plan_Masse_01_Editb.jpg" alt="agence-1">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-21/Chartres_Mirage_Salon_Rendu_01b.jpg" data-fancybox="images-1">
                  <img src="assets/img/logements/article-21/Chartres_Mirage_Salon_Rendu_01b.jpg" alt="agence-2">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-21/article-21-3.jpg" data-fancybox="images-1">
                  <img src="assets/img/logements/article-21/article-21-3.jpg" alt="agence-3">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-21/article-21-4.jpg" data-fancybox="images-1">
                  <img src="assets/img/logements/article-21/article-21-4.jpg" alt="agence-4">
                </a>
              </div>
            </div>
            <!--.logement-lightbox-->
          </div>
          <!--.logement-item-->
        </div>
        <!--.project-item-->

        <div class="project-item harvest-color">
          <div class="project-item_desc">
            <div class="project-item_title">
              <h3>74 logements en accession commune de chartres (28)</h3>
            </div>
            <div class="project-item_bigImg">
              <img src="assets/img/logements/article-45/article-45-1-thumb.jpg" alt="article-45-1-thumb">
            </div>
          </div>
          <!--.project-item_desc-->
          <div class="logement-item">
            <div class="logement-item-desc">
              <p>Opération : 3 Immeubles totalisant 74 logements</p>
              <p>Maître d'ouvrage : SPI Promotion</p>
              <p>Mission : Complète à 100%</p>
              <p>Montant des travaux : 6 890 000 € ht</p>
              <p>Surface : 4450 m2 SHAB</p>
              <p>Date de livraison : -</p>
            </div>
            <!--.logement-item-desc-->
            <div class="logement-lightbox">
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-45/imgpsh_fullsize_anim-1.jpg" data-fancybox="images-2">
                  <img src="assets/img/logements/article-45/imgpsh_fullsize_anim-1.jpg" alt="navarre-1">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-45/imgpsh_fullsize_anim-2.jpg" data-fancybox="images-2">
                  <img src="assets/img/logements/article-45/imgpsh_fullsize_anim-2.jpg" alt="navarre-2">
                </a>
              </div>
            </div>
          </div>
          <!--.logement-item-->
        </div>
        <!--.project-item-->

      </div>
      <!--.project-item_first-->

      <div class="project-item_second">



        <div class="project-item harvest-color">
          <div class="project-item_desc">
            <div class="project-item_title">
              <h3>127 LOGEMENTS COMMUNE DE CHARTRES (28)</h3>
            </div>
            <div class="project-item_bigImg">
              <img src="assets/img/logements/article-49/vue-1.jpg"
                alt="46-logements-thumb" />
            </div>
          </div>
          <!--.project-item_desc-->
          <div class="logement-item">
            <div class="logement-item-desc">
              <p>Opération : Construction de 127 logements</p>
              <p>Maitre d’ouvrage : AXIOM PROMOTEUR</p>
              <p>Mission : Complète à 100%</p>
              <p>Montant des travaux : 11 500 000 € ht</p>
              <p>Surface : 8350 m2 SHAB</p>
              <p>Date de livraison : -</p>
            </div>
            <!--.logement-item-desc-->
            <div class="logement-lightbox">
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-49/vue-1.jpg" data-fancybox="images-25">
                  <img src="assets/img/logements/article-49/vue-1.jpg" alt="1">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-49/vue-2.jpg" data-fancybox="images-25">
                  <img src="assets/img/logements/article-49/vue-2.jpg" alt="2">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-49/vue-3.jpg" data-fancybox="images-25">
                  <img src="assets/img/logements/article-49/vue-3.jpg" alt="3">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-49/vue-4.jpg" data-fancybox="images-25">
                  <img src="assets/img/logements/article-49/vue-4.jpg" alt="3">
                </a>
              </div>
            </div>
            <!--.logement-lightbox-->
          </div>
          <!--.logement-item-->
        </div>
        <!--.project-item-->

        <div class="project-item harvest-color">
          <div class="project-item_desc">
            <div class="project-item_title">
              <h3>42 logements sociaux commune de Lucé (28)</h3>
            </div>
            <div class="project-item_bigImg">
              <img src="assets/img/logements/article-47/toitures-tuiles-angle-entree.jpg"
                alt="42-logements-thumb" />
            </div>
          </div>
          <!--.project-item_desc-->

          <div class="logement-item">
            <div class="logement-item-desc">
              <p>Opération : Construction de 43 logements</p>
              <p>Maitre d’ouvrage : Axiom Promoteur</p>
              <p>Mission : Conception (PCG)</p>
              <p>Montant des travaux : 3 400 000 € ht</p>
              <p>Surface : 2850 m2 SHAB</p>
              <p>Date de livraison : Printemps 2022</p>
            </div>
            <!--.logement-item-desc-->
            <div class="logement-lightbox">
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-47/toitures-tuiles-angle-entree.jpg" data-fancybox="images-23">
                  <img src="assets/img/logements/article-47/toitures-tuiles-angle-entree.jpg" alt="43-logements-1">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-47/toiture-tuile-angle-jardin.jpg" data-fancybox="images-23">
                  <img src="assets/img/logements/article-47/toiture-tuile-angle-jardin.jpg" alt="43-logements-2">
                </a>
              </div>
            <!--   <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-43/43-logements-3.jpg" data-fancybox="images-23">
                  <img src="assets/img/logements/article-43/43-logements-3.jpg" alt="43-logements-3">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-43/43-logements-4.jpg" data-fancybox="images-23">
                  <img src="assets/img/logements/article-43/43-logements-4.jpg" alt="43-logements-4">
                </a>
              </div> -->
            </div>
            <!--.logement-lightbox-->
          </div>
          <!--.logement-item-->

        </div>
        <!--.project-item-->
        <div class="project-item harvest-color">
          <div class="project-item_desc">
            <div class="project-item_title">
              <h3>92 Logements commune de Lucé</h3>
            </div>
            <div class="project-item_bigImg">
              <img src="assets/img/logements/article-48/DROIT-1.jpg" alt="92-logements-thumb">
            </div>
          </div>
          <!--.project-item_desc-->

          <div class="logement-item">
            <div class="logement-item-desc">
              <p>Opération : Construction de 92 logements (43 sociaux et 49 libres)</p>
              <p>Maître d'ouvrage : Alila Promotion</p>
              <p>Mission : Conception (PCG)</p>
              <p>Montant des travaux :  6 562 000 € ht</p>
              <p>Surface : 5468 m2 SHAB</p>
              <p>Date de livraisons :</p>
            </div>
            <!--.logement-item-desc-->

            <div class="logement-lightbox">
              <div class="logement-lightbox_img">
                <!-- <a href="assets/img/logements/article-44/92-logements-1.jpg" data-fancybox="images-24">
                  <img src="assets/img/logements/article-44/92-logements-1.jpg" alt="92-logements-1">
                </a> -->
                <a href="assets/img/logements/article-48/DROIT-1.jpg" data-fancybox="images-24">
                  <img src="assets/img/logements/article-48/DROIT-1.jpg" alt="92-logements-1">
                </a>
              </div>
              <div class="logement-lightbox_img">
               <!--  <a href="assets/img/logements/article-44/92-logements-2.jpg" data-fancybox="images-24">
                  <img src="assets/img/logements/article-44/92-logements-2.jpg" alt="92-logements-2">
                </a> -->
                <a href="assets/img/logements/article-48/DROIT-2.jpg" data-fancybox="images-24">
                  <img src="assets/img/logements/article-48/DROIT-2.jpg" alt="92-logements-1">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <!-- <a href="assets/img/logements/article-44/92-logements-3.jpg" data-fancybox="images-24">
                  <img src="assets/img/logements/article-44/92-logements-3.jpg" alt="92-logements-3">
                </a> -->
                <a href="assets/img/logements/article-48/DROIT-3.jpg" data-fancybox="images-24">
                  <img src="assets/img/logements/article-48/DROIT-3.jpg" alt="92-logements-1">
                </a>
              </div>
            </div>
            <!--.logement-lightbox-->
          </div>
          <!--.logement-item-->

        </div>
        <!--.project-item-->
        <div class="project-item harvest-color">
          <div class="project-item_desc">
            <div class="project-item_title">
              <h3>71 logements en accession commune de chartres (28)</h3>
            </div>
            <div class="project-item_bigImg">
              <img src="assets/img/logements/article-23/article-23-2-thumb.jpg" alt="article-23-2">
            </div>
          </div>
          <!--.project-item_desc-->

          <div class="logement-item">
            <div class="logement-item-desc">
              <p>Opération : Construction d'un immeuble de 71 logements</p>
              <p>Maître d'ouvrage : Kaufmann & Broad</p>
              <p>Mission : Complète à 100%</p>
              <p>Montant des travaux : 6 300 000 € ht</p>
              <p>Surface : 4325 m2 SHAB</p>
              <p>Date de livraison : 2021/2022</p>
            </div>
            <!--.logement-item-desc-->
            <div class="logement-lightbox">
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-23/article-23-1.jpg" data-fancybox="images-3">
                  <img src="assets/img/logements/article-23/article-23-1.jpg" alt="article-23-1">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-23/article-23-6.png" data-fancybox="images-3">
                  <img src="assets/img/logements/article-23/article-23-6.png" alt="article-23-6">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-23/article-23-3.jpg" data-fancybox="images-3">
                  <img src="assets/img/logements/article-23/article-23-3.jpg" alt="article-23-3">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-23/article-23-4.jpg" data-fancybox="images-3">
                  <img src="assets/img/logements/article-23/article-23-4.jpg" alt="article-23-4">
                </a>
              </div>
            </div>
            <!--.logement-lightbox-->
          </div>
          <!--.logement-item-->

        </div>
        <!--.project-item-->
        <div class="project-item harvest-color ">
          <div class="project-item_desc">
            <div class="project-item_title">
              <!-- <h3>68 logements en accession à Mainvilliers (28)</h3> -->
              <h3>36 LOGEMENTS EN ACCESSION COMMUNE DE MAINVILLIERS (28)</h3>
            </div>
            <div class="project-item_bigImg">
              <img src="assets/img/logements/article-42/vue-1.jpg" alt="kb-chateau-thumb">
            </div>
          </div>
          <!--.project-item_desc-->

          <div class="logement-item">
            <div class="logement-item-desc">
              <p>Opération : 36 logements</p>
              <p>Maître d'ouvrage : SPI et Kaufmann & Broad</p>
              <p>Mission : Complète à 100%</p>
              <p>Montant des travaux : 4 605 650 € ht</p>
              <p>Surface : 3240 m2 SHAB</p>
              <p>Date de livraisons : -</p>
            </div>
            <!--.logement-item-desc-->

            <div class="logement-lightbox">
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-42/vue-1.jpg" data-fancybox="images-22">
                  <img src="assets/img/logements/article-42/vue-1.jpg" alt="kb-chateau-1">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-42/vue-2.jpg" data-fancybox="images-22">
                  <img src="assets/img/logements/article-42/vue-2.jpg" alt="kb-chateau-2">
                </a>
              </div>
            </div>
            <!--.logement-lightbox-->
          </div>
          <!--.logement-item-->
        </div>
        <!--.project-item-->
        <div class="project-item harvest-color">
          <div class="project-item_desc">
            <div class="project-item_title">
              <h3>41 logements sociaux bbc le vallon fleuri - st sebastien de morsent (27)</h3>
            </div>
            <div class="project-item_bigImg">
              <img src="assets/img/logements/article-24/article-24-1-thumb.jpg" alt="article-24-1">
            </div>
          </div>
          <!--.project-item_desc-->

          <div class="logement-item">
            <div class="logement-item-desc">
              <p>Opération : Lotissement de 41 logements sociaux BBC</p>
              <p>Maître d'ouvrage : SAIEM</p>
              <p>Mission : Complète à 100%</p>
              <p>Montant des travaux : 4 276 578 € ht</p>
              <p>Surface : 4070 m2 SP</p>
              <p>Date de livraison : 04/015</p>
            </div>
            <!--.logement-item-desc-->
            <div class="logement-lightbox">
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-24/article-24-1.jpg" data-fancybox="images-4">
                  <img src="assets/img/logements/article-24/article-24-1.jpg" alt="article-24-1">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-24/article-24-2.jpg" data-fancybox="images-4">
                  <img src="assets/img/logements/article-24/article-24-2.jpg" alt="article-24-2">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-24/article-24-3.jpg" data-fancybox="images-4">
                  <img src="assets/img/logements/article-24/article-24-3.jpg" alt="article-24-3">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-24/article-24-4.jpg" data-fancybox="images-4">
                  <img src="assets/img/logements/article-24/article-24-4.jpg" alt="article-24-4">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-24/article-24-5.jpg" data-fancybox="images-4">
                  <img src="assets/img/logements/article-24/article-24-5.jpg" alt="article-24-5">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-24/article-24-6.jpg" data-fancybox="images-4">
                  <img src="assets/img/logements/article-24/article-24-6.jpg" alt="article-24-6">
                </a>
              </div>
            </div>
            <!--.logement-lightbox-->
          </div>
          <!--.logement-item-->

        </div>
        <!--.project-item-->
        <div class="project-item harvest-color">
          <div class="project-item_desc">
            <div class="project-item_title">
              <h3>immeuble de 9 logements bbc commune de cannes (06 - alpes maritimes)</h3>
            </div>
            <div class="project-item_bigImg">
              <img src="assets/img/logements/article-25/article-25-2-thumb.jpg" alt="article-25-2">
            </div>
          </div>
          <!--.project-item_desc-->

          <div class="logement-item">
            <div class="logement-item-desc">
              <p>Opération : Immeuble de 9 logements basse consommation (RT 2005 - 50%)</p>
              <p>Maître d'ouvrage : Sianna Promotion</p>
              <p>Mission : Complète à 100%</p>
              <p>Montant des travaux : 1 438 400 € ht</p>
              <p>Surface : 833 m2 SP</p>
              <p>Date de livraison : Juin 2014</p>
            </div>
            <div class="logement-lightbox">
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-25/article-25-1.jpg" data-fancybox="images-5">
                  <img src="assets/img/logements/article-25/article-25-1.jpg" alt="article-25-1">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-25/article-25-2.jpg" data-fancybox="images-5">
                  <img src="assets/img/logements/article-25/article-25-2.jpg" alt="article-25-2">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-25/article-25-3.jpg" data-fancybox="images-5">
                  <img src="assets/img/logements/article-25/article-25-3.jpg" alt="article-25-3">
                </a>
              </div>

            </div>
          </div>

        </div>
        <!--.project-item-->
        <div class="project-item harvest-color">
          <div class="project-item_desc">
            <div class="project-item_title">
              <h3>36 logements en accession commune d'evreux (27)</h3>
            </div>
            <div class="project-item_bigImg">
              <img src="assets/img/logements/article-26/article-26-3-thumb.jpg" alt="article-26-3">
            </div>
          </div>
          <!--.project-item_desc-->

          <div class="logement-item">
            <div class="logement-item-desc">
              <p>Opération : Immeuble de 36 logements</p>
              <p>Maître d'ouvrage : ABE Immobilier (privé)</p>
              <p>Mission : Complète à 100%</p>
              <p>Montant des travaux : 2 052 148 € ht</p>
              <p>Surface : 2097 m2 SHON</p>
              <p>Date de livraison : Livré le 31 décembre 2011</p>
            </div>
            <!--.logement-item-desc-->
            <div class="logement-lightbox">
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-26/article-26-1.jpg" data-fancybox="images-6">
                  <img src="assets/img/logements/article-26/article-26-1.jpg" alt="article-26-1">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-26/article-26-2.jpg" data-fancybox="images-6">
                  <img src="assets/img/logements/article-26/article-26-2.jpg" alt="article-26-2">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-26/article-26-3.jpg" data-fancybox="images-6">
                  <img src="assets/img/logements/article-26/article-26-3.jpg" alt="article-26-3">
                </a>
              </div>
            </div>
            <!--.logement-lightbox-->
          </div>
          <!--.logement-item-->

        </div>
        <!--.project-item-->
        <div class="project-item harvest-color">
          <div class="project-item_desc">
            <div class="project-item_title">
              <h3>immeuble de 25 logements bbc commune d'evreux (27)</h3>
            </div>
            <div class="project-item_bigImg">
              <img src="assets/img/logements/article-27/article-27-2-thumb.jpg" alt="article-27-2">
            </div>
          </div>
          <!--.project-item_desc-->

          <div class="logement-item">
            <div class="logement-item-desc">
              <p>Opération : Immeuble de 25 logements BBC</p>
              <p>Maître d'ouvrage : SCI Le Loft</p>
              <p>Mission : Complète à 100%</p>
              <p>Montant des travaux : 1 500 000 € ht</p>
              <p>Surface : 983 m2 SHON</p>
              <p>Date de livraison : Octobre 2013</p>
            </div>
            <!--.logement-item-desc-->
            <div class="logement-lightbox">
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-27/article-27-1.jpg" data-fancybox="images-7">
                  <img src="assets/img/logements/article-27/article-27-1.jpg" alt="article-27-1">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-27/article-27-2.jpg" data-fancybox="images-7">
                  <img src="assets/img/logements/article-27/article-27-2.jpg" alt="article-27-2">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-27/article-27-3.jpg" data-fancybox="images-7">
                  <img src="assets/img/logements/article-27/article-27-3.jpg" alt="article-27-3">
                </a>
              </div>

            </div>
            <!--.logement-lightbox-->
          </div>
          <!--.logement-item-->

        </div>
        <!--.project-item-->
      </div>
      <!--.project-item_second-->






      <div class="project-item_first">
        <div class="project-item harvest-color">
          <div class="project-item_desc">
            <div class="project-item_title">
              <h3>immeuble de 24 logements et 9 maisons rt 2012 commune d'evreux (27)</h3>
            </div>
            <div class="project-item_bigImg">
              <img src="assets/img/logements/article-28/article-28-2-thumb.jpg" alt="/article-28-2">
            </div>
          </div>
          <!--.project-item_desc-->

          <div class="logement-item">
            <div class="logement-item-desc">
              <p>Opération : Immeuble de 24 logements et 9 maisons RT 2012</p>
              <p>Maître d'ouvrage : ABE immobilier</p>
              <p>Mission : Complète à 100%</p>
              <p>Montant des travaux : 1 680 000,00 € ht</p>
              <p>Surface : 1400 m2 SP</p>
              <p>Date de livraison : Décembre 2015</p>
            </div>
            <!--.logement-item-desc-->
            <div class="logement-lightbox">
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-28/article-28-1.jpg" data-fancybox="images-8">
                  <img src="assets/img/logements/article-28/article-28-1.jpg" alt="article-28-1">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-28/article-28-2.jpg" data-fancybox="images-8">
                  <img src="assets/img/logements/article-28/article-28-2.jpg" alt="article-28-2">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-28/article-28-3.jpg" data-fancybox="images-8">
                  <img src="assets/img/logements/article-28/article-28-3.jpg" alt="article-28-3">
                </a>
              </div>
            </div>
            <!--.logement-lightbox-->
          </div>
          <!--.logement-item-->

        </div>
        <!--.project-item-->
        <div class="project-item harvest-color">
          <div class="project-item_desc">
            <div class="project-item_title">
              <h3>immeuble de 28 logements certifies bbc rue du panorama - evreux (27)</h3>
            </div>
            <div class="project-item_bigImg">
              <img src="assets/img/logements/article-29/article-29-1-thumb.jpg" alt="/article-29-1">
            </div>
          </div>
          <!--.project-item_desc-->

          <div class="logement-item">
            <div class="logement-item-desc">
              <p>Opération : Immeuble de 28 logements BBC Certification BBC obtenue</p>
              <p>Maître d'ouvrage : SCI Villa 65</p>
              <p>Mission : Complète à 100%</p>
              <p>Montant des travaux : 1 979 068,01 € ht</p>
              <p>Surface : 1602 m2 SHON</p>
              <p>Date de livraison : Mars 2013</p>
            </div>
            <!--.logement-item-desc-->
            <div class="logement-lightbox">
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-29/article-29-1.jpg" data-fancybox="images-9">
                  <img src="assets/img/logements/article-29/article-29-1.jpg" alt="article-29-1">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-29/article-29-2.jpg" data-fancybox="images-9">
                  <img src="assets/img/logements/article-29/article-29-2.jpg" alt="article-29-2">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-29/article-29-3.jpg" data-fancybox="images-9">
                  <img src="assets/img/logements/article-29/article-29-3.jpg" alt="article-29-3">
                </a>
              </div>
            </div>
            <!--.logement-lightbox-->
          </div>
          <!--.logement-item-->

        </div>
        <!--.project-item-->
      </div>
      <!--.project-item_first-->

      <div class="project-item_second">
        <div class="project-item harvest-color">
          <div class="project-item_desc">
            <div class="project-item_title">
              <h3>23 maisons sociales et en accession commune de leves (28)</h3>
            </div>
            <div class="project-item_bigImg">
              <img src="assets/img/logements/article-30/article-30-1-thumb.jpg" alt="/article-30-1">
            </div>
          </div>
          <!--.project-item_desc-->

          <div class="logement-item">
            <div class="logement-item-desc">
              <p>Opération : 23 maisons</p>
              <p>Maître d'ouvrage : Concept Promotion</p>
              <p>Mission : Complète à 100%</p>
              <p>Montant des travaux : 2 265 000 € ht</p>
              <p>Surface : 1655 m2 SHAB</p>
              <p>Date de livraison : -</p>
            </div>
            <!--.logement-item-desc-->
            <div class="logement-lightbox">
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-30/article-30-1.jpg" data-fancybox="images-10">
                  <img src="assets/img/logements/article-30/article-30-1.jpg" alt="article-30-1">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-30/article-30-2.jpg" data-fancybox="images-10">
                  <img src="assets/img/logements/article-30/article-30-2.jpg" alt="article-30-2">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-30/article-30-3.jpg" data-fancybox="images-10">
                  <img src="assets/img/logements/article-30/article-30-3.jpg" alt="article-30-3">
                </a>
              </div>

            </div>
            <!--.logement-lightbox-->
          </div>
          <!--.logement-item-->

        </div>
        <!--.project-item-->
        <div class="project-item harvest-color">
          <div class="project-item_desc">
            <div class="project-item_title">
              <h3>8 maisons a caractere social certifiees bbc av. d'artois / bd d'alsace - bonneval (28)</h3>
            </div>
            <div class="project-item_bigImg">
              <img src="assets/img/logements/article-31/article-31-1-thumb.jpg" alt="article-31-1">
            </div>
          </div>
          <!--.project-item_desc-->

          <div class="logement-item">
            <div class="logement-item-desc">
              <p>Opération : Lotissement de 8 maisons BBC Certification obtenue</p>
              <p>Maître d'ouvrage : SA Eure et Loir Habitat</p>
              <p>Mission : Complète à 100%</p>
              <p>Montant des travaux : 1 069 548,03 € ht</p>
              <p>Surface : 943 m2 SHON</p>
              <p>Date de livraison : Avril 2012</p>
            </div>
            <!--.logement-item-desc-->
            <div class="logement-lightbox">
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-31/article-31-1.jpg" data-fancybox="images-11">
                  <img src="assets/img/logements/article-31/article-31-1.jpg" alt="article-31-1">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-31/article-31-2.jpg" data-fancybox="images-11">
                  <img src="assets/img/logements/article-31/article-31-2.jpg" alt="article-31-2">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-31/article-31-3.jpg" data-fancybox="images-11">
                  <img src="assets/img/logements/article-31/article-31-3.jpg" alt="article-31-3">
                </a>
              </div>

            </div>
            <!--.logement-lightbox-->
          </div>
          <!--.logement-item-->

        </div>
        <!--.project-item-->
        <div class="project-item harvest-color">
          <div class="project-item_desc">
            <div class="project-item_title">
              <h3>22 logements individuels a caractere social, rue de chavannes - leves (28)</h3>
            </div>
            <div class="project-item_bigImg">
              <img src="assets/img/logements/article-32/article-32-3-thumb.jpg" alt="article-32-3">
            </div>
          </div>
          <!--.project-item_desc-->

          <div class="logement-item">
            <div class="logement-item-desc">
              <p>Opération : Construction de 22 logements individuels RT 2012 - 10%</p>
              <p>Maître d'ouvrage : SA Eure et Loir Habitat</p>
              <p>Mission : Complète à 100%</p>
              <p>Montant des travaux : 3 080 770 € ht</p>
              <p>Surface : 1922 m2</p>
              <p>Date de livraison : Fin 2019</p>
            </div>
            <!--.logement-item-desc-->
            <div class="logement-lightbox">
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-32/article-32-1.jpg" data-fancybox="images-12">
                  <img src="assets/img/logements/article-32/article-32-1.jpg" alt="article-32-1">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-32/article-32-2.jpg" data-fancybox="images-12">
                  <img src="assets/img/logements/article-32/article-32-2.jpg" alt="article-32-2">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-32/article-32-3.jpg" data-fancybox="images-12">
                  <img src="assets/img/logements/article-32/article-32-3.jpg" alt="article-32-3">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-32/article-32-4.jpg" data-fancybox="images-12">
                  <img src="assets/img/logements/article-32/article-32-4.jpg" alt="article-32-4">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-32/article-32-5.jpg" data-fancybox="images-12">
                  <img src="assets/img/logements/article-32/article-32-5.jpg" alt="article-32-5">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-32/article-32-6.jpg" data-fancybox="images-12">
                  <img src="assets/img/logements/article-32/article-32-6.jpg" alt="article-32-6">
                </a>
              </div>
            </div>
            <!--.logement-lightbox-->
          </div>
          <!--.logement-item-->

        </div>
        <!--.project-item-->
        <div class="project-item harvest-color">
          <div class="project-item_desc">
            <div class="project-item_title">
              <h3>30 logements collectifs & 4 maisons a caractere social - rue f. foreau - luce (28)</h3>
            </div>
            <div class="project-item_bigImg">
              <img src="assets/img/logements/article-33/article-33-1-thumb.jpg" alt="article-33-1">
            </div>
          </div>
          <!--.project-item_desc-->

          <div class="logement-item">
            <div class="logement-item-desc">
              <p>Opération : Construction de 4 maisons et 30 logements collectifs - RT 2012 - 20%</p>
              <p>Maître d'ouvrage : SA Eure et Loir Habitat</p>
              <p>Mission : Complète à 100%</p>
              <p>Montant des travaux : 3 056 000,00 € ht</p>
              <p>Surface : 2265 m2</p>
              <p>Date de livraison : Mai 2019</p>
            </div>
            <!--.logement-item-desc-->

            <div class="logement-lightbox">
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-33/article-33-1.jpg" data-fancybox="images-13">
                  <img src="assets/img/logements/article-33/article-33-1.jpg" alt="article-33-1">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-33/article-33-2.jpg" data-fancybox="images-13">
                  <img src="assets/img/logements/article-33/article-33-2.jpg" alt="article-33-2">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-33/article-33-3.jpg" data-fancybox="images-13">
                  <img src="assets/img/logements/article-33/article-33-3.jpg" alt="article-33-3">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-33/article-33-4.jpg" data-fancybox="images-13">
                  <img src="assets/img/logements/article-33/article-33-4.jpg" alt="article-33-4">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-33/article-33-5.jpg" data-fancybox="images-13">
                  <img src="assets/img/logements/article-33/article-33-5.jpg" alt="article-33-5">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-33/article-33-6.jpg" data-fancybox="images-13">
                  <img src="assets/img/logements/article-33/article-33-6.jpg" alt="article-33-6">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-33/article-33-7.jpg" data-fancybox="images-13">
                  <img src="assets/img/logements/article-33/article-33-7.jpg" alt="article-33-7">
                </a>
              </div>
            </div>
            <!--.logement-lightbox-->
          </div>
          <!--.logement-item-->

        </div>
        <!--.project-item-->
        <div class="project-item harvest-color">
          <div class="project-item_desc">
            <div class="project-item_title">
              <h3>10 maisons bbc a caractere social rue du bel air + rue bles d'or - guichainville (27)</h3>
            </div>
            <div class="project-item_bigImg">
              <img src="assets/img/logements/article-34/article-34-1-thumb.jpg" alt="article-34-1">
            </div>
          </div>
          <!--.project-item_desc-->

          <div class="logement-item">
            <div class="logement-item-desc">
              <p>Opération : Lotissement de 10 maisons - 1 groupe de 4 maisons - 1 groupe de 6 maisons Résultats tests à
                l'air
                0.26 (pour 0.60 exigés) Certification BBC obtenue (Cerqual)</p>
              <p>Maître d'ouvrage : Eure Habitat</p>
              <p>Mission : Complète à 100%</p>
              <p>Montant des travaux : 1 154 937 € ht</p>
              <p>Surface : 962 m2</p>
              <p>Date de livraison : Mars 2014</p>
            </div>
            <!--.logement-item-desc-->
            <div class="logement-lightbox">
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-34/article-34-1.jpg" data-fancybox="images-14">
                  <img src="assets/img/logements/article-34/article-34-1.jpg" alt="article-34-1">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-34/article-34-2.jpg" data-fancybox="images-14">
                  <img src="assets/img/logements/article-34/article-34-2.jpg" alt="article-34-2">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-34/article-34-3.jpg" data-fancybox="images-14">
                  <img src="assets/img/logements/article-34/article-34-3.jpg" alt="article-34-3">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-34/article-34-4.jpg" data-fancybox="images-14">
                  <img src="assets/img/logements/article-34/article-34-4.jpg" alt="article-34-4">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-34/article-34-5.jpg" data-fancybox="images-14">
                  <img src="assets/img/logements/article-34/article-34-5.jpg" alt="article-34-5">
                </a>
              </div>
            </div>
            <!--.logement-lightbox-->
          </div>
          <!--.logement-item-->

        </div>
        <!--.project-item-->
        <div class="project-item harvest-color">
          <div class="project-item_desc">
            <div class="project-item_title">
              <h3>22 maisons individuelles privees commune de luisant (28)</h3>
            </div>
            <div class="project-item_bigImg">
              <img src="assets/img/logements/article-35/article-35-1-thumb.jpg" alt="article-35-1">
            </div>
          </div>
          <!--.project-item_desc-->

          <div class="logement-item">

            <div class="logement-item-desc">
              <p>Opération : Construction de 22 Maisons Norme RT 2012</p>
              <p>Maître d'ouvrage : EXEO Promotion</p>
              <p>Mission : PCG 60 %</p>
              <p>Montant des travaux : 1 500 000,00 € ht</p>
              <p>Surface : 1467 m2</p>
            </div>
            <!--.logement-item-desc-->
            <div class="logement-lightbox">
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-35/article-35-1.jpg" data-fancybox="images-15">
                  <img src="assets/img/logements/article-35/article-35-1.jpg" alt="article-35-1">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-35/article-35-2.jpg" data-fancybox="images-15">
                  <img src="assets/img/logements/article-35/article-35-2.jpg" alt="article-35-2">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-35/article-35-3.jpg" data-fancybox="images-15">
                  <img src="assets/img/logements/article-35/article-35-3.jpg" alt="article-35-3">
                </a>
              </div>
            </div>
            <!--.logement-lightbox-->
          </div>
          <!--.logement-item-->

        </div>
        <!--.project-item-->
      </div>
      <!--.project-item_second-->



      <div class="project-item_first">
        <div class="project-item harvest-color">
          <div class="project-item_desc">
            <div class="project-item_title">
              <h3>construction de 15 logements sociaux zac des fenots - dreux 28</h3>
            </div>
            <div class="project-item_bigImg">
              <img src="assets/img/logements/article-36/article-36-2-thumb.jpg" alt="article-36-2">
            </div>
          </div>
          <!--.project-item_desc-->

          <div class="logement-item">
            <div class="logement-item-desc">
              <p>Opération : 15 Logements sociaux</p>
              <p>Maître d'ouvrage : Habitat Drouais</p>
              <p>Mission : Complète à 100%</p>
              <p>Montant des travaux : 2 068 400 € ht</p>
              <p>Surface habitables: bât.1 : 906,10m2 - bât.2 : 519,09m2</p>
              <p>Date de livraison : Fin 2019</p>
            </div>
            <!--.logement-item-desc-->
            <div class="logement-lightbox">
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-36/article-36-1.jpg" data-fancybox="images-16">
                  <img src="assets/img/logements/article-36/article-36-1.jpg" alt="article-36-1">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-36/article-36-2.jpg" data-fancybox="images-16">
                  <img src="assets/img/logements/article-36/article-36-2.jpg" alt="article-36-2">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-36/article-36-3.jpg" data-fancybox="images-16">
                  <img src="assets/img/logements/article-36/article-36-3.jpg" alt="article-36-3">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-36/article-36-4.jpg" data-fancybox="images-16">
                  <img src="assets/img/logements/article-36/article-36-4.jpg" alt="article-36-4">
                </a>
              </div>
            </div>
            <!--.logement-lightbox-->
          </div>
          <!--.logement-item-->

        </div>
        <!--.project-item-->
        <div class="project-item harvest-color">
          <div class="project-item_desc">
            <div class="project-item_title">
              <h3>10 logements sociaux rt 2012 la garenne - gasville-oiseme (28)</h3>
            </div>
            <div class="project-item_bigImg">
              <img src="assets/img/logements/article-37/article-37-1-thumb.jpg" alt="article-37-1">
            </div>
          </div>
          <!--.project-item_desc-->

          <div class="logement-item">
            <div class="logement-item-desc">
              <p>Opération : 10 Logements sociaux RT 2012</p>
              <p>Maître d'ouvrage : Habitat Eurélien</p>
              <p>Mission : Complète à 100%</p>
              <p>Montant des travaux : 1 245 500 € ht</p>
              <p>Surface plancher : 914 m2</p>
              <p>Date de livraison : AVRIL 2018</p>
            </div>
            <!--.logement-item-desc-->
            <div class="logement-lightbox">
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-37/article-37-1.jpg" data-fancybox="images-17">
                  <img src="assets/img/logements/article-37/article-37-1.jpg" alt="article-37-1">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-37/article-37-2.jpg" data-fancybox="images-17">
                  <img src="assets/img/logements/article-37/article-37-2.jpg" alt="article-37-2">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-37/article-37-3.jpg" data-fancybox="images-17">
                  <img src="assets/img/logements/article-37/article-37-3.jpg" alt="article-37-3">
                </a>
              </div>
            </div>
            <!--.logement-lightbox-->
          </div>
          <!--.logement-item-->

        </div>
        <!--.project-item-->
      </div>
      <!--.project-item_first-->

      <div class="project-item_second">
        <div class="project-item harvest-color">
          <div class="project-item_desc">
            <div class="project-item_title">
              <h3>transformation des anciens bains-douches de chateaudun en maison d'habitation</h3>
            </div>
            <div class="project-item_bigImg">
              <img src="assets/img/logements/article-38/article-38-1-thumb.jpg" alt="article-38-1">
            </div>
          </div>
          <!--.project-item_desc-->

          <div class="logement-item">
            <div class="logement-item-desc">
              <p>Opération : Maison d'habitation</p>
              <p>Maître d'ouvrage : Privé</p>
              <p>Mission : Complète à 100%</p>
              <p>Montant des travaux : 250 000 € ht</p>
              <p>SHON : 270 m2</p>
              <p>Date de livraison : mai 2013</p>
            </div>
            <!--.logement-item-desc-->
            <div class="logement-lightbox">
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-38/article-38-1.jpg" data-fancybox="images-18">
                  <img src="assets/img/logements/article-38/article-38-1.jpg" alt="article-38-1">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-38/article-38-2.jpg" data-fancybox="images-18">
                  <img src="assets/img/logements/article-38/article-38-2.jpg" alt="article-38-2">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-38/article-38-3.jpg" data-fancybox="images-18">
                  <img src="assets/img/logements/article-38/article-38-3.jpg" alt="article-38-3">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-38/article-38-4.jpg" data-fancybox="images-18">
                  <img src="assets/img/logements/article-38/article-38-4.jpg" alt="article-38-4">
                </a>
              </div>
            </div>
            <!--.logement-lightbox-->
          </div>
          <!--.logement-item-->

        </div>
        <!--.project-item-->
        <div class="project-item harvest-color">
          <div class="project-item_desc">
            <div class="project-item_title">
              <h3>15 logements sociaux - nogent perche habitat rue du val roquet - nogent le rotrou (28)</h3>
            </div>
            <div class="project-item_bigImg">
              <img src="assets/img/logements/article-39/article-39-1.jpg" alt="article-39-1">
            </div>
          </div>
          <!--.project-item_desc-->

          <div class="logement-item">
            <div class="logement-item-desc">
              <p>Opération : Construction de 15 logements - RT 2012</p>
              <p>Maître d'ouvrage : Pierres & Territoires</p>
              <p>Mission : Complète à 100%</p>
              <p>Montant des travaux : 1 090 800 € ht</p>
              <p>Surface utile : 923 m2</p>
              <p>Livraison : Octobre 2018</p>
            </div>
            <!--.logement-item-desc-->
            <div class="logement-lightbox">
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-39/article-39-1.jpg" data-fancybox="images-19">
                  <img src="assets/img/logements/article-39/article-39-1.jpg" alt="article-39-1">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-39/article-39-2.jpg" data-fancybox="images-19">
                  <img src="assets/img/logements/article-39/article-39-2.jpg" alt="article-39-2">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-39/article-39-3.jpg" data-fancybox="images-19">
                  <img src="assets/img/logements/article-39/article-39-3.jpg" alt="article-39-3">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-39/article-39-4.jpg" data-fancybox="images-19">
                  <img src="assets/img/logements/article-39/article-39-4.jpg" alt="article-39-4">
                </a>
              </div>
            </div>
            <!--.logement-lightbox-->
          </div>
          <!--.logement-item-->

        </div>
        <!--.project-item-->
        <div class="project-item harvest-color">
          <div class="project-item_desc">
            <div class="project-item_title">
              <h3>immeuble de 12 logements bbc commune de saint-raphael (83 - var)</h3>
            </div>
            <div class="project-item_bigImg">
              <img src="assets/img/logements/article-40/article-40-2-thumb.jpg" alt="article-40-2">
            </div>
          </div>
          <!--.project-item_desc-->

          <div class="logement-item">
            <div class="logement-item-desc">
              <p>Opération : Immeuble de 12 logements basse consommation (RT 2005 - 50%)</p>
              <p>Maître d'ouvrage : Sianna Promotion</p>
              <p>Mission : Complète à 100%</p>
              <p>Montant des travaux : 1 000 000 € ht</p>
              <p>Surface : 654 m2 SP</p>
              <p>Livraison : Mars 2014</p>
            </div>
            <!--.logement-item-desc-->
            <div class="logement-lightbox">
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-40/article-40-1.jpg" data-fancybox="images-20">
                  <img src="assets/img/logements/article-40/article-40-1.jpg" alt="article-40-1">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-40/article-40-2.jpg" data-fancybox="images-20">
                  <img src="assets/img/logements/article-40/article-40-2.jpg" alt="article-40-2">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-40/article-40-3.jpg" data-fancybox="images-20">
                  <img src="assets/img/logements/article-40/article-40-3.jpg" alt="article-40-3">
                </a>
              </div>

            </div>
            <!--.logement-lightbox-->
          </div>
          <!--.logement-item-->

        </div>
        <!--.project-item-->
        <div class="project-item harvest-color">
          <div class="project-item_desc">
            <div class="project-item_title">
              <h3>18 logements a caractere social - bd seine - le manoir (27)</h3>
            </div>
            <div class="project-item_bigImg">
              <img src="assets/img/logements/article-41/article-41-1-thumb.jpg" alt="article-41-1">
            </div>
          </div>
          <!--.project-item_desc-->

          <div class="logement-item">
            <div class="logement-item-desc">
              <p>Opération : Opération de 18 logements (6 + 12 en RT 2012)</p>
              <p>Maître d'ouvrage : Siloge</p>
              <p>Mission : Complète à 100%</p>
              <p>Montant des travaux : 1 792 493 € ht</p>
              <p>Surface : 1608 m2 SP</p>
              <p>Date de livraisons :</p>
              <p>Phase 1 : Nov. 2014 </p>
              <p>Phase 2 : Sept 2017 (RT 2012)</p>
            </div>
            <!--.logement-item-desc-->

            <div class="logement-lightbox">
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-41/article-41-1.jpg" data-fancybox="images-21">
                  <img src="assets/img/logements/article-41/article-41-1.jpg" alt="article-41-1">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-41/article-41-2.jpg" data-fancybox="images-21">
                  <img src="assets/img/logements/article-41/article-41-2.jpg" alt="article-41-2">
                </a>
              </div>
              <div class="logement-lightbox_img">
                <a href="assets/img/logements/article-41/article-41-3.jpg" data-fancybox="images-21">
                  <img src="assets/img/logements/article-41/article-41-3.jpg" alt="article-41-3">
                </a>
              </div>
            </div>
            <!--.logement-lightbox-->
          </div>
          <!--.logement-item-->

        </div>
        <!--.project-item-->

      </div>
      <!--.project-item_second-->
    </div>
    <!--.projects-items-area-->


  </div>
  <!--.main-content-->
</section>
<!--.logements-area-->
