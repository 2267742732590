<div class="section-header section-parallax section-1">
    <app-social-media></app-social-media>
    <div class="main-content">
        <div class="caption">
            <p>
                Qualité et sobriété architecturale
                <br> Adaptation et convivialité des espaces
            </p>
        </div>
        <!--.caption-->
    </div>
    <!--.main-content-->
</div>
<!--.section-header-->


<section class="logements-area">
    <div class="main-content">
        <div class="section_title black-style">
            <h1>nos projets et créations - Industriel</h1>
        </div>

        <div class="accordion-area">
            <accordion [closeOthers]="oneAtATime" [isAnimated]="true">
                <accordion-group heading="Laboratoire du chocolatier Patrick Roger" [isOpen]="isFirstOpen">

                    <div class="logement-item">
                        <div class="logement-item-desc">
                            <p>
                                Patrick Roger, chocolatier de renommée internationale a confié à l’Agence MS Architecture la réalisation de son laboratoire
                                à Sceaux (92).
                            </p>
                            <p>
                                Ce projet, logé au sein d’une ancienne imprimerie de 1500 m2 abrite un laboratoire expérimental de confection, une salle
                                de préparation des produits finalisés, des bureaux en mezzanine et des chambres froides.
                            </p>
                        </div>
                        <!--.logement-item-desc-->

                        <div class="logement-lightbox">
                            <div class="logement-lightbox_img">
                                <a href="assets/img/industriel/article-1/pr.jpg" data-fancybox="images">
                                    <img src="assets/img/industriel/article-1/pr_m.jpg" alt="pr_m">
                                </a>
                            </div>

                        </div>
                        <!--.logement-lightbox-->
                    </div>
                    <!--.logement-item-->

                </accordion-group>

            </accordion>
        </div>
        <!--.accordion-area-->

    </div>
    <!--.main-content-->
</section>
<!--.logements-area-->