<footer>
    <div class="main-content">
        <div class="footer-blc">
            <p class="copyright">
                © {{fullYear}} Copyright MS-Architecture. Tous droits réservés.
            </p>
            <ul class="footer-social-media">
                <li>
                  <a  class="icon facebook-icn" href="https://www.facebook.com/MSArchitecture28/" target="_blank"></a>
                </li>
                <!--<li>
                    <a class="icon instagram-icn"></a>
                </li>-->
                <li>
                    <a class="icon linkedin-icn" href="https://www.linkedin.com/in/yann-sidoroff-01294411/" target="_blank"></a>
                </li>
            </ul>
            <!--.footer-social-media-->
        </div>
        <!--.footer-blc-->
        <div class="footer-blc">
            <div class="footer-links">
                <ul>
                    <li class="copy-right">
                        <a>Created by © 2020 Webify technology</a>
                    </li>
                </ul>
            </div>
            <!--.footer-links-->
        </div>
        <!--.footer-blc-->
    </div>
    <!--.main-content-->
</footer>
